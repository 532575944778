import React, { useState, useEffect, useContext } from "react";
import SuccessModal from "../../components/SuccessModal";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../axiosInstance";
import Loader from "../../components/Loader";
import AOS from "aos";
import "aos/dist/aos.css";

const addContactUs = (payload) => {
  return axiosInstance.post(`/contact-us`, payload);
};

const ContactUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      contact: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      contact: Yup.string()
        .required("Mobile Number is required")
        .matches(/^[0-9]{10}$/, "Must be exactly 10 digits"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      subject: Yup.string().required("subject is required"),
      message: Yup.string().required("State is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await addContactUs(values);
        console.log("Form submitted:", response.data);

        setModalMessage(`Thank you for Contact Us ${values.name}!`);
        setLoading(false);
        setShowModal(true);
        resetForm();
      } catch (error) {
        console.error("Error submitting the form:", error);
        setModalMessage(
          "There was an error submitting the form. Please try again."
        );
        setLoading(false);
        setShowModal(true);
      }
    },
  });

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Contact Us: Get in Touch with IMPC</title>
        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta
          name="description"
          content="Reach out to IMPC for inquiries, support, or information on our initiatives. Contact us via email, phone, or visit our office. We're here to help!"
        />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />
        <meta
          property="og:title"
          content="Contact Us: Get in Touch with IMPC"
        />
        <meta
          property="og:description"
          content="Reach out to IMPC for inquiries, support, or information on our initiatives. Contact us via email, phone, or visit our office. We're here to help!"
        />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta property="og:url" content="https://www.impc.co.in/contact-us" />
        <meta property="og:type" content="website" />

        <link rel="canonical" href="https://www.impc.co.in/contact-us" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      {loading && <Loader active={loading} />}
      <section className="ContactUS-banner">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <h1 className="heading text-center">
                {translations.ContactUsTittle}
              </h1>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="ContactUS-form mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group-row">
                          <label className="form-label" htmlFor="name">
                            {translations.Name}
                            <span>*</span>
                          </label>
                          <input
                            className="form-control form-field"
                            name="name"
                            id="name"
                            type="text"
                            placeholder="Please enter first name"
                            {...formik.getFieldProps("name")}
                            onKeyPress={(event) => {
                              if (!/^[a-zA-Z]+$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="error">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-row">
                          <label className="form-label" htmlFor="contact">
                            {translations.ContactNumber}
                            <span>*</span>
                          </label>
                          <input
                            className="form-control form-field"
                            name="contact"
                            id="contact"
                            type="text"
                            placeholder="Please enter contact number"
                            maxLength="10"
                            {...formik.getFieldProps("contact")}
                          />
                          {formik.touched.contact && formik.errors.contact ? (
                            <div className="error">{formik.errors.contact}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-row">
                          <label className="form-label" htmlFor="email">
                            {translations.YourEmail}
                            <span>*</span>
                          </label>
                          <input
                            className="form-control form-field"
                            name="email"
                            id="email"
                            type="email"
                            placeholder="Please enter your email id"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-row">
                          <label className="form-label" htmlFor="subject">
                            {translations.Subject}
                            <span>*</span>
                          </label>
                          <input
                            className="form-control form-field"
                            name="subject"
                            id="subject"
                            type="text"
                            placeholder="please enter subject"
                            {...formik.getFieldProps("subject")}
                          />
                          {formik.touched.subject && formik.errors.subject ? (
                            <div className="error">{formik.errors.subject}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-row">
                          <label className="form-label" htmlFor="message">
                            {translations.Message}
                            <span>*</span>
                          </label>
                          <textarea
                            className="form-control form-field"
                            rows="4"
                            name="message"
                            id="message"
                            placeholder="please enter a subject"
                            {...formik.getFieldProps("message")}
                          />
                          {formik.touched.message && formik.errors.message ? (
                            <div className="error">{formik.errors.message}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-row mb-0">
                          <button type="submit" className="btn btn-submit">
                            {translations.SUBMIT}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="container">
                      <div className="row">
                        <div className="about-info">
                          <div className="address">
                            <ul>
                              <li className="col-12">
                                <h4 className="mb-1">
                                  <i className="fa fa-building"></i>
                                  {translations.ContactUsHeaddingDelhiOffice}
                                </h4>
                                <p>{translations.ContactUsAddress_1}</p>
                              </li>
                              <li className="col-12">
                                <h4 className="mb-1">
                                  <i className="fa fa-building"></i>
                                  {translations.ContactUsHeaddingGurgaonOffice}
                                </h4>
                                <p>{translations.ContactUsAddress_2}</p>
                              </li>
                              <li className="col-12">
                                <h4 className="mb-1">
                                  <i className="fa fa-envelope"></i>
                                  {translations.Email}
                                </h4>
                                <a href="mailto:impcorg@gmail.com">
                                  {translations.mailAddress}
                                </a>
                              </li>
                              {/* <li className="col-12">
                                <h4 className="mb-1">
                                  <i className="fa fa-phone"></i> Get In Touch
                                </h4>
                                <a href="tel:011-46068711">011-46068711</a>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {showModal && (
        <SuccessModal
          message={modalMessage}
          show={showModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default ContactUs;
