import React, { useEffect, useContext } from "react";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import AOS from "aos";
import "aos/dist/aos.css";

const TermsAndConditions = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <div className="privacy-policy-banner">
      <div className="header-part  text-center py-3 container">
        <h1>{translations.TermsAndConditionsTittle}</h1>
      </div>

      <div className="container py-5">
        <div className="privacy-policy-content">
          <h4>{translations.TermsAndConditionsHeadding_1}</h4>
          <p>{translations.TermsAndConditionsDescription_1}</p>

          <h4>{translations.TermsAndConditionsHeadding_2}</h4>
          <p>{translations.TermsAndConditionsDescription_2}:</p>

          <p>{translations.TermsAndConditionsDescription_3}</p>

          <p>{translations.TermsAndConditionsDescription_4}</p>

          <h4>{translations.TermsAndConditionsHeadding_3}</h4>

          <p>{translations.TermsAndConditionsDescription_5}</p>

          <h4>{translations.TermsAndConditionsHeadding_4}</h4>

          <p>{translations.TermsAndConditionsDescription_6}</p>

          <h4>{translations.TermsAndConditionsHeadding_5}</h4>

          <p>{translations.TermsAndConditionsDescription_7}</p>

          <h4>{translations.TermsAndConditionsHeadding_6}</h4>

          <p>{translations.TermsAndConditionsDescription_8}</p>

          <h4>{translations.TermsAndConditionsHeadding_7}</h4>

          <p>{translations.TermsAndConditionsDescription_9}</p>

          <p>{translations.TermsAndConditionsDescription_10}</p>

          <h4>{translations.TermsAndConditionsHeadding_8}</h4>

          <p>{translations.TermsAndConditionsDescription_11}</p>

          <h4>{translations.TermsAndConditionsHeadding_9}</h4>
          <p>
            {translations.TermsAndConditionsDescription_12}
            <a href="mailto:info@impc.co.in">[info@impc.co.in]</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
