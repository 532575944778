import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import SuccessModal from "../../components/SuccessModal";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../axiosInstance";
import Loader from "../../components/Loader";

const adoptATemple = (payload) => {
  return axiosInstance.post(`/adoption`, payload);
};

const getaTemple = () => {
  return axiosInstance.get(`/temple`);
};

const AdoptATempleForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [temples, setTemples] = useState([]);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { types: ["(regions)"] }
      );

      autocomplete.setFields(["address_components"]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        console.log("Place object:", place);

        const state = place.address_components?.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        const country = place.address_components?.find((component) =>
          component.types.includes("country")
        )?.long_name;

        const city = place.address_components?.find((component) =>
          component.types.includes("locality")
        )?.long_name;

        if (country && state && city) {
          formik.setFieldValue("country", country);
          formik.setFieldValue("state", state);
          formik.setFieldValue("city", city);
        }
      });
    }
  }, []);

  useEffect(() => {
    const fetchPlants = async () => {
      try {
        const response = await getaTemple();
        setTemples(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching plant data:", error);
      }
    };
    fetchPlants();
  }, []);

  const formik = useFormik({
    initialValues: {
      adoption_choice: "Temple",
      f_name: "",
      country: "",
      state: "",
      city: "",
      email: "",
      phone: "",
      duration: "",
      temple: "",
      quote_count: "",
    },
    validationSchema: Yup.object({
      f_name: Yup.string().required("First Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      country: Yup.string().required("Country is required"),
      phone: Yup.string()
        .required("Mobile Number is required")
        .matches(/^[0-9]{10}$/, "Must be exactly 10 digits"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      duration: Yup.string().required("duration is required"),
      quote_count: Yup.number()
        .required("Amount is required")
        .min(1, "Minimum amount is 1"),
    }),
    onSubmit: (values) => {
      const amount = values.quote_count;

      handlePayment(amount, values);
    },
  });

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePayment = useCallback((amount, formValues) => {
    const options = {
      //key: "rzp_test_zmdDxQzJkadwMA", // test key
      key: "rzp_live_wanb2nCfu7zO2t", // live Key
      amount: amount * 100, // amount in paise
      currency: "INR",
      name: "IMPC",
      description: "One Stop solution for Devotion",
      image: "https://bhagvabucket.s3.amazonaws.com/logo.png",
      handler: async (response) => {
        console.log(response);
        const payload = {
          adoption_choice: formValues.adoption_choice,
          f_name: formValues.f_name,
          country: formValues.country,
          state: formValues.state,
          city: formValues.city,
          email: formValues.email,
          phone: formValues.phone,
          temple: formValues.temple_id,
          duration: formValues.duration,
          quote_count: formValues.quote_count,
          payment_id: response.razorpay_payment_id,
        };

        try {
          const apiResponse = await adoptATemple(payload);
          setModalMessage(
            "Thank you for your generous donation! Your support helps us plant a tree and build a temple, creating a greener and more spiritual future for all. 🌳🏛️"
          );
          setShowModal(true);
          formik.resetForm();
          setTemples([]);
        } catch (error) {
          setModalMessage(
            "There was an error submitting the form. Please try again."
          );
          setShowModal(true);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new window.Razorpay(options);
    rzpay.on("payment.failed", (response) => {
      setModalMessage("Payment failed. Please try again.");
      setShowModal(true);
    });
    rzpay.open();
  }, []);

  const handleRadioChange = (event) => {
    formik.setFieldValue("duration", event.target.value);
  };

  return (
    <>
      {loading && <Loader active={loading} />}
      <section className="getInvolved-banner">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="heading text-center">
                <h1 style={{ fontSize: "2.1rem" }}>
                  {translations.AdoptATempleFormTittle}
                </h1>
                <p className="temple-form-title">
                  <strong>{translations.AdoptATempleFormDescription_1}:</strong>{" "}
                  {"  "}
                  {translations.AdoptATempleFormDescription_2}
                </p>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="getInvolved-contact-form mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Name}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="f_name"
                        type="text"
                        placeholder="Please enter name"
                        {...formik.getFieldProps("f_name")}
                        onKeyPress={(event) => {
                          if (!/^[a-zA-Z]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {formik.touched.f_name && formik.errors.f_name ? (
                        <div className="error">{formik.errors.f_name}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Email}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="email"
                        type="email"
                        placeholder="Please enter email"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.PhoneNumber}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="phone"
                        type="tel"
                        placeholder="Please enter mobile number"
                        maxLength="10"
                        {...formik.getFieldProps("phone")}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="error">{formik.errors.phone}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.City}
                        <span>*</span>
                      </label>
                      <input
                        name="city"
                        className="form-control form-field"
                        {...formik.getFieldProps("city")}
                        ref={autocompleteRef}
                      />
                      {formik.touched.city && formik.errors.city ? (
                        <div className="error">{formik.errors.city}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Country}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="country"
                        {...formik.getFieldProps("country")}
                        placeholder="Please enter your country"
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <div className="error">{formik.errors.country}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.State}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-field"
                        name="state"
                        {...formik.getFieldProps("state")}
                        placeholder="Enter State"
                      />
                      {formik.touched.state && formik.errors.state ? (
                        <div className="error">{formik.errors.state}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label" >
                        {translations.ChooseATemple}
                        <span>*</span>
                      </label>
                      <select
                        name="temple_id"
                        className="form-control form-field"
                        {...formik.getFieldProps("temple_id")}
                      >
                        <option value="" style={{fontSize: "14px", fontWeight: "normal"}}>Select a temple</option>
                        {temples.map((temple) => (
                          <option key={temple.id} value={temple.id}>
                            {temple.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched.temple_id && formik.errors.temple_id ? (
                        <div className="error">{formik.errors.temple_id}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group-row">
                        <label className="form-label">
                          {translations.DonateTowardsTempleManagement}{" "}
                          <span>*</span>
                        </label>
                        <div className="numberOfCows">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="duration"
                              value="1 Month"
                              checked={formik.values.duration === "1 Month"}
                              onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                              {translations.Month1}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="duration"
                              value="3 Months"
                              checked={formik.values.duration === "3 Months"}
                              onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                              {translations.Month3}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="duration"
                              value="1 Year"
                              checked={formik.values.duration === "1 Year"}
                              onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                              {translations.Year1}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="duration"
                              value="5 Years"
                              checked={formik.values.duration === "5 Years"}
                              onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                              {translations.Year5}
                            </label>
                          </div>
                          {formik.touched.duration && formik.errors.duration ? (
                            <div
                              className="error"
                              style={{ color: "red", marginTop: "5px" }}
                            >
                              {formik.errors.duration}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group-row">
                        <label className="form-label">
                          {
                            translations.UponDonationExactDetailsWillBeSharedWithTheDonorOverEmail
                          }{" "}
                          <span>*</span>
                        </label>
                        <input
                          className="form-control form-field"
                          name="quote_count"
                          type="text"
                          placeholder="Please enter your amount"
                          {...formik.getFieldProps("quote_count")}
                        />
                        {formik.touched.quote_count &&
                        formik.errors.quote_count ? (
                          <div className="error">
                            {formik.errors.quote_count}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group-row">
                      <button type="submit" className="btn btn-submit">
                        {translations.SUBMIT}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {showModal && (
        <SuccessModal
          message={modalMessage}
          show={showModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default AdoptATempleForm;
