import React, { useState, useEffect, useContext, useRef } from "react";
import SuccessModal from "../../components/SuccessModal";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../axiosInstance";
import Loader from "../../components/Loader";

const addCommittieMember = (payload) => {
  return axiosInstance.post(`/participant`, payload);
};

const JoinAsCommittieMember = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const autocompleteRef = useRef(null);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { types: ["(regions)"] }
      );

      autocomplete.setFields(["address_components"]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        console.log("Place object:", place);

        const state = place.address_components?.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        const country = place.address_components?.find((component) =>
          component.types.includes("country")
        )?.long_name;

        const city = place.address_components?.find((component) =>
          component.types.includes("locality")
        )?.long_name;

        if (country && state && city) {
          formik.setFieldValue("country", country);
          formik.setFieldValue("state", state);
          formik.setFieldValue("city", city);
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      country_code: "91",
      contact: "",
      email: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      address: "",
      role: 3,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      country_code: Yup.string().required("Country Code is required"),
      contact: Yup.string()
        .required("Mobile Number is required")
        .matches(/^[0-9]{10}$/, "Must be exactly 10 digits"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^[0-9]{6}$/, "Must be exactly 6 digits"),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await addCommittieMember(values);
        console.log("Form submitted:", response.data);

        setModalMessage(
          "We appreciate your interest in becoming a Committie Member. A confirmation email with further details has been sent to your inbox. Please check your email for the next steps, and feel free to reach out if you need any assistance."
        );
        setLoading(false);
        setShowModal(true);
        resetForm();
      } catch (error) {
        console.error("Error submitting the form:", error);
        setModalMessage(
          "There was an error submitting the form. Please try again."
        );
        setLoading(false);
        setShowModal(true);
      }
    },
  });

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Helmet>
        <title>Join IMPC: Become a Committee Member</title>
        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta name="description" content="" />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />

        <meta
          property="og:title"
          content="Join IMPC: Become a Committee Member"
        />
        <meta property="og:description" content="" />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.impc.co.in/join-as-a-committie-member"
        />
        <meta property="og:type" content="website" />

        <link
          rel="canonical"
          href="https://www.impc.co.in/join-as-a-committie-member"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      {loading && <Loader active={loading} />}
      <section className="getInvolved-banner">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="heading text-center">
                <h1 style={{ fontSize: "2.1rem" }}>
                 {translations.JoinAsACommitteeMemberTittle}
                </h1>
                {/* <p>
                  Donate Your generous donations help us in our mission to
                  preserve heritage, empower <br />
                  communities, and ensure environmental sustainability. Every
                  contribution makes a significant impact.
                </p> */}
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="getInvolved-contact-form mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.FirstName}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="first_name"
                        type="text"
                        placeholder="Please enter first name"
                        {...formik.getFieldProps("first_name")}
                        onKeyPress={(event) => {
                          if (!/^[a-zA-Z]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="error">{formik.errors.first_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.LastName}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="last_name"
                        type="text"
                        placeholder="Please enter last name"
                        {...formik.getFieldProps("last_name")}
                        onKeyPress={(event) => {
                          if (!/^[a-zA-Z]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="error">{formik.errors.last_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4 col-5">
                        <div className="form-group-row">
                          <label className="form-label">
                            {translations.CountryCode}
                            <span>*</span>
                          </label>
                          <select
                            className="form-control form-field"
                            name="country_code"
                            {...formik.getFieldProps("country_code")}
                          >
                            <option data-countrycode="IN" value="91">
                              (+91)
                            </option>
                            <optgroup label="Other countries">
                              <option data-countrycode="DZ" value="213">
                                (+213)
                              </option>
                              <option data-countrycode="AD" value="376">
                                (+376)
                              </option>
                              <option data-countrycode="AO" value="244">
                                (+244)
                              </option>
                              <option data-countrycode="AI" value="1264">
                                (+1264)
                              </option>
                              <option data-countrycode="AG" value="1268">
                                (+1268)
                              </option>
                              <option data-countrycode="AR" value="54">
                                (+54)
                              </option>
                              <option data-countrycode="AM" value="374">
                                (+374)
                              </option>
                              <option data-countrycode="AW" value="297">
                                (+297)
                              </option>
                              <option data-countrycode="AU" value="61">
                                (+61)
                              </option>
                              <option data-countrycode="AT" value="43">
                                (+43)
                              </option>
                              <option data-countrycode="AZ" value="994">
                                (+994)
                              </option>
                              <option data-countrycode="BS" value="1242">
                                (+1242)
                              </option>
                              <option data-countrycode="BH" value="973">
                                (+973)
                              </option>
                              <option data-countrycode="BD" value="880">
                                (+880)
                              </option>
                              <option data-countrycode="BB" value="1246">
                                (+1246)
                              </option>
                              <option data-countrycode="BY" value="375">
                                (+375)
                              </option>
                              <option data-countrycode="BE" value="32">
                                (+32)
                              </option>
                              <option data-countrycode="BZ" value="501">
                                (+501)
                              </option>
                              <option data-countrycode="BJ" value="229">
                                (+229)
                              </option>
                              <option data-countrycode="BM" value="1441">
                                (+1441)
                              </option>
                              <option data-countrycode="BT" value="975">
                                (+975)
                              </option>
                              <option data-countrycode="BO" value="591">
                                (+591)
                              </option>
                              <option data-countrycode="BA" value="387">
                                (+387)
                              </option>
                              <option data-countrycode="BW" value="267">
                                (+267)
                              </option>
                              <option data-countrycode="BR" value="55">
                                (+55)
                              </option>
                              <option data-countrycode="BN" value="673">
                                (+673)
                              </option>
                              <option data-countrycode="BG" value="359">
                                (+359)
                              </option>
                              <option data-countrycode="BF" value="226">
                                (+226)
                              </option>
                              <option data-countrycode="BI" value="257">
                                (+257)
                              </option>
                              <option data-countrycode="KH" value="855">
                                (+855)
                              </option>
                              <option data-countrycode="CM" value="237">
                                (+237)
                              </option>
                              <option data-countrycode="CA" value="1">
                                (+1)
                              </option>
                              <option data-countrycode="CV" value="238">
                                (+238)
                              </option>
                              <option data-countrycode="KY" value="1345">
                                (+1345)
                              </option>
                              <option data-countrycode="CF" value="236">
                                (+236)
                              </option>
                              <option data-countrycode="CL" value="56">
                                (+56)
                              </option>
                              <option data-countrycode="CN" value="86">
                                (+86)
                              </option>
                              <option data-countrycode="CO" value="57">
                                (+57)
                              </option>
                              <option data-countrycode="KM" value="269">
                                (+269)
                              </option>
                              <option data-countrycode="CG" value="242">
                                (+242)
                              </option>
                              <option data-countrycode="CK" value="682">
                                (+682)
                              </option>
                              <option data-countrycode="CR" value="506">
                                (+506)
                              </option>
                              <option data-countrycode="HR" value="385">
                                (+385)
                              </option>
                              <option data-countrycode="CU" value="53">
                                (+53)
                              </option>
                              <option data-countrycode="CY" value="90392">
                                (+90392)
                              </option>
                              <option data-countrycode="CY" value="357">
                                (+357)
                              </option>
                              <option data-countrycode="CZ" value="42">
                                (+42)
                              </option>
                              <option data-countrycode="DK" value="45">
                                (+45)
                              </option>
                              <option data-countrycode="DJ" value="253">
                                (+253)
                              </option>
                              <option data-countrycode="DM" value="1809">
                                (+1809)
                              </option>
                              <option data-countrycode="DO" value="1809">
                                (+1809)
                              </option>
                              <option data-countrycode="EC" value="593">
                                (+593)
                              </option>
                              <option data-countrycode="EG" value="20">
                                (+20)
                              </option>
                              <option data-countrycode="SV" value="503">
                                (+503)
                              </option>
                              <option data-countrycode="GQ" value="240">
                                (+240)
                              </option>
                              <option data-countrycode="ER" value="291">
                                (+291)
                              </option>
                              <option data-countrycode="EE" value="372">
                                (+372)
                              </option>
                              <option data-countrycode="ET" value="251">
                                (+251)
                              </option>
                              <option data-countrycode="FK" value="500">
                                (+500)
                              </option>
                              <option data-countrycode="FO" value="298">
                                (+298)
                              </option>
                              <option data-countrycode="FJ" value="679">
                                (+679)
                              </option>
                              <option data-countrycode="FI" value="358">
                                (+358)
                              </option>
                              <option data-countrycode="FR" value="33">
                                (+33)
                              </option>
                              <option data-countrycode="GF" value="594">
                                (+594)
                              </option>
                              <option data-countrycode="PF" value="689">
                                (+689)
                              </option>
                              <option data-countrycode="GA" value="241">
                                (+241)
                              </option>
                              <option data-countrycode="GM" value="220">
                                (+220)
                              </option>
                              <option data-countrycode="GE" value="7880">
                                (+7880)
                              </option>
                              <option data-countrycode="DE" value="49">
                                (+49)
                              </option>
                              <option data-countrycode="GH" value="233">
                                (+233)
                              </option>
                              <option data-countrycode="GI" value="350">
                                (+350)
                              </option>
                              <option data-countrycode="GR" value="30">
                                (+30)
                              </option>
                              <option data-countrycode="GL" value="299">
                                (+299)
                              </option>
                              <option data-countrycode="GD" value="1473">
                                (+1473)
                              </option>
                              <option data-countrycode="GP" value="590">
                                (+590)
                              </option>
                              <option data-countrycode="GU" value="671">
                                (+671)
                              </option>
                              <option data-countrycode="GT" value="502">
                                (+502)
                              </option>
                              <option data-countrycode="GN" value="224">
                                (+224)
                              </option>
                              <option data-countrycode="GW" value="245">
                                (+245)
                              </option>
                              <option data-countrycode="GY" value="592">
                                (+592)
                              </option>
                              <option data-countrycode="HT" value="509">
                                (+509)
                              </option>
                              <option data-countrycode="HN" value="504">
                                (+504)
                              </option>
                              <option data-countrycode="HK" value="852">
                                (+852)
                              </option>
                              <option data-countrycode="HU" value="36">
                                (+36)
                              </option>
                              <option data-countrycode="IS" value="354">
                                (+354)
                              </option>
                              <option data-countrycode="IN" value="91">
                                (+91)
                              </option>
                              <option data-countrycode="ID" value="62">
                                (+62)
                              </option>
                              <option data-countrycode="IR" value="98">
                                Iran (+98)
                              </option>
                              <option data-countrycode="IQ" value="964">
                                Iraq (+964)
                              </option>
                              <option data-countrycode="IE" value="353">
                                (+353)
                              </option>
                              <option data-countrycode="IL" value="972">
                                (+972)
                              </option>
                              <option data-countrycode="IT" value="39">
                                (+39)
                              </option>
                              <option data-countrycode="JM" value="1876">
                                (+1876)
                              </option>
                              <option data-countrycode="JP" value="81">
                                (+81)
                              </option>
                              <option data-countrycode="JO" value="962">
                                (+962)
                              </option>
                              <option data-countrycode="KZ" value="7">
                                (+7)
                              </option>
                              <option data-countrycode="KE" value="254">
                                (+254)
                              </option>
                              <option data-countrycode="KI" value="686">
                                (+686)
                              </option>
                              <option data-countrycode="KP" value="850">
                                (+850)
                              </option>
                              <option data-countrycode="KR" value="82">
                                (+82)
                              </option>
                              <option data-countrycode="KW" value="965">
                                (+965)
                              </option>
                              <option data-countrycode="KG" value="996">
                                (+996)
                              </option>
                              <option data-countrycode="LA" value="856">
                                (+856)
                              </option>
                              <option data-countrycode="LV" value="371">
                                (+371)
                              </option>
                              <option data-countrycode="LB" value="961">
                                (+961)
                              </option>
                              <option data-countrycode="LS" value="266">
                                (+266)
                              </option>
                              <option data-countrycode="LR" value="231">
                                (+231)
                              </option>
                              <option data-countrycode="LY" value="218">
                                Libya (+218)
                              </option>
                              <option data-countrycode="LI" value="417">
                                (+417)
                              </option>
                              <option data-countrycode="LT" value="370">
                                (+370)
                              </option>
                              <option data-countrycode="LU" value="352">
                                (+352)
                              </option>
                              <option data-countrycode="MO" value="853">
                                Macao (+853)
                              </option>
                              <option data-countrycode="MK" value="389">
                                (+389)
                              </option>
                              <option data-countrycode="MG" value="261">
                                (+261)
                              </option>
                              <option data-countrycode="MW" value="265">
                                (+265)
                              </option>
                              <option data-countrycode="MY" value="60">
                                (+60)
                              </option>
                              <option data-countrycode="MV" value="960">
                                (+960)
                              </option>
                              <option data-countrycode="ML" value="223">
                                (+223)
                              </option>
                              <option data-countrycode="MT" value="356">
                                (+356)
                              </option>
                              <option data-countrycode="MH" value="692">
                                (+692)
                              </option>
                              <option data-countrycode="MQ" value="596">
                                (+596)
                              </option>
                              <option data-countrycode="MR" value="222">
                                (+222)
                              </option>
                              <option data-countrycode="YT" value="269">
                                (+269)
                              </option>
                              <option data-countrycode="MX" value="52">
                                (+52)
                              </option>
                              <option data-countrycode="FM" value="691">
                                (+691)
                              </option>
                              <option data-countrycode="MD" value="373">
                                (+373)
                              </option>
                              <option data-countrycode="MC" value="377">
                                (+377)
                              </option>
                              <option data-countrycode="MN" value="976">
                                (+976)
                              </option>
                              <option data-countrycode="MS" value="1664">
                                (+1664)
                              </option>
                              <option data-countrycode="MA" value="212">
                                (+212)
                              </option>
                              <option data-countrycode="MZ" value="258">
                                (+258)
                              </option>
                              <option data-countrycode="MN" value="95">
                                (+95)
                              </option>
                              <option data-countrycode="NA" value="264">
                                (+264)
                              </option>
                              <option data-countrycode="NR" value="674">
                                (+674)
                              </option>
                              <option data-countrycode="NP" value="977">
                                (+977)
                              </option>
                              <option data-countrycode="NL" value="31">
                                (+31)
                              </option>
                              <option data-countrycode="NC" value="687">
                                (+687)
                              </option>
                              <option data-countrycode="NZ" value="64">
                                (+64)
                              </option>
                              <option data-countrycode="NI" value="505">
                                (+505)
                              </option>
                              <option data-countrycode="NE" value="227">
                                (+227)
                              </option>
                              <option data-countrycode="NG" value="234">
                                (+234)
                              </option>
                              <option data-countrycode="NU" value="683">
                                (+683)
                              </option>
                              <option data-countrycode="NF" value="672">
                                (+672)
                              </option>
                              <option data-countrycode="NP" value="670">
                                (+670)
                              </option>
                              <option data-countrycode="NO" value="47">
                                (+47)
                              </option>
                              <option data-countrycode="OM" value="968">
                                (+968)
                              </option>
                              <option data-countrycode="PW" value="680">
                                (+680)
                              </option>
                              <option data-countrycode="PA" value="507">
                                (+507)
                              </option>
                              <option data-countrycode="PG" value="675">
                                (+675)
                              </option>
                              <option data-countrycode="PY" value="595">
                                (+595)
                              </option>
                              <option data-countrycode="PE" value="51">
                                (+51)
                              </option>
                              <option data-countrycode="PH" value="63">
                                (+63)
                              </option>
                              <option data-countrycode="PL" value="48">
                                (+48)
                              </option>
                              <option data-countrycode="PT" value="351">
                                (+351)
                              </option>
                              <option data-countrycode="PR" value="1787">
                                (+1787)
                              </option>
                              <option data-countrycode="QA" value="974">
                                (+974)
                              </option>
                              <option data-countrycode="RE" value="262">
                                (+262)
                              </option>
                              <option data-countrycode="RO" value="40">
                                (+40)
                              </option>
                              <option data-countrycode="RU" value="7">
                                (+7)
                              </option>
                              <option data-countrycode="RW" value="250">
                                (+250)
                              </option>
                              <option data-countrycode="SM" value="378">
                                (+378)
                              </option>
                              <option data-countrycode="ST" value="239">
                                (+239)
                              </option>
                              <option data-countrycode="SA" value="966">
                                (+966)
                              </option>
                              <option data-countrycode="SN" value="221">
                                (+221)
                              </option>
                              <option data-countrycode="CS" value="381">
                                (+381)
                              </option>
                              <option data-countrycode="SC" value="248">
                                (+248)
                              </option>
                              <option data-countrycode="SL" value="232">
                                (+232)
                              </option>
                              <option data-countrycode="SG" value="65">
                                (+65)
                              </option>
                              <option data-countrycode="SK" value="421">
                                (+421)
                              </option>
                              <option data-countrycode="SI" value="386">
                                (+386)
                              </option>
                              <option data-countrycode="SB" value="677">
                                (+677)
                              </option>
                              <option data-countrycode="SO" value="252">
                                (+252)
                              </option>
                              <option data-countrycode="ZA" value="27">
                                (+27)
                              </option>
                              <option data-countrycode="ES" value="34">
                                (+34)
                              </option>
                              <option data-countrycode="LK" value="94">
                                (+94)
                              </option>
                              <option data-countrycode="SH" value="290">
                                (+290)
                              </option>
                              <option data-countrycode="KN" value="1869">
                                (+1869)
                              </option>
                              <option data-countrycode="SC" value="1758">
                                (+1758)
                              </option>
                              <option data-countrycode="SD" value="249">
                                (+249)
                              </option>
                              <option data-countrycode="SR" value="597">
                                (+597)
                              </option>
                              <option data-countrycode="SZ" value="268">
                                (+268)
                              </option>
                              <option data-countrycode="SE" value="46">
                                (+46)
                              </option>
                              <option data-countrycode="CH" value="41">
                                (+41)
                              </option>
                              <option data-countrycode="SI" value="963">
                                (+963)
                              </option>
                              <option data-countrycode="TW" value="886">
                                (+886)
                              </option>
                              <option data-countrycode="TJ" value="7">
                                (+7)
                              </option>
                              <option data-countrycode="TH" value="66">
                                (+66)
                              </option>
                              <option data-countrycode="TG" value="228">
                                (+228)
                              </option>
                              <option data-countrycode="TO" value="676">
                                (+676)
                              </option>
                              <option data-countrycode="TT" value="1868">
                                (+1868)
                              </option>
                              <option data-countrycode="TN" value="216">
                                (+216)
                              </option>
                              <option data-countrycode="TR" value="90">
                                (+90)
                              </option>
                              <option data-countrycode="TM" value="7">
                                (+7)
                              </option>
                              <option data-countrycode="TM" value="993">
                                (+993)
                              </option>
                              <option data-countrycode="TC" value="1649">
                                (+1649)
                              </option>
                              <option data-countrycode="TV" value="688">
                                (+688)
                              </option>
                              <option data-countrycode="UG" value="256">
                                (+256)
                              </option>
                              <option data-countrycode="UA" value="380">
                                (+380)
                              </option>
                              <option data-countrycode="AE" value="971">
                                (+971)
                              </option>
                              <option data-countrycode="UY" value="598">
                                (+598)
                              </option>
                              <option data-countrycode="UZ" value="7">
                                (+7)
                              </option>
                              <option data-countrycode="VU" value="678">
                                (+678)
                              </option>
                              <option data-countrycode="VA" value="379">
                                (+379)
                              </option>
                              <option data-countrycode="VE" value="58">
                                (+58)
                              </option>
                              <option data-countrycode="VN" value="84">
                                (+84)
                              </option>
                              <option data-countrycode="VG" value="84">
                                (+1284)
                              </option>
                              <option data-countrycode="VI" value="84">
                                (+1340)
                              </option>
                              <option data-countrycode="WF" value="681">
                                (+681)
                              </option>
                              <option data-countrycode="YE" value="969">
                                (+969)
                              </option>
                              <option data-countrycode="YE" value="967">
                                (+967)
                              </option>
                              <option data-countrycode="ZM" value="260">
                                (+260)
                              </option>
                              <option data-countrycode="ZW" value="263">
                                (+263)
                              </option>
                            </optgroup>
                          </select>
                          {formik.touched.country_code &&
                          formik.errors.country_code ? (
                            <div className="error">
                              {formik.errors.country_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-8 col-7">
                        <div className="form-group-row">
                          <label className="form-label">
                            {translations.MobileNo}
                            <span>*</span>
                          </label>
                          <input
                            className="form-control form-field"
                            name="contact"
                            type="tel"
                            placeholder="Please enter mobile number"
                            maxLength="10"
                            {...formik.getFieldProps("contact")}
                          />
                          {formik.touched.contact && formik.errors.contact ? (
                            <div className="error">{formik.errors.contact}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Email}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        type="email"
                        name="email"
                        placeholder="Please enter your email id"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.City}
                        <span>*</span>
                      </label>
                      <input
                        name="city"
                        className="form-control form-field"
                        {...formik.getFieldProps("city")}
                        ref={autocompleteRef}
                      />
                      {formik.touched.city && formik.errors.city ? (
                        <div className="error">{formik.errors.city}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Country}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="country"
                        placeholder="Enter country"
                        {...formik.getFieldProps("country")}
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <div className="error">{formik.errors.country}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.State}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-field"
                        name="state"
                        {...formik.getFieldProps("state")}
                        placeholder="Enter State"
                      />
                      {formik.touched.state && formik.errors.state ? (
                        <div className="error">{formik.errors.state}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Pincode}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="pincode"
                        type="tel"
                        maxLength="10"
                        placeholder="Please enter your location pincode"
                        {...formik.getFieldProps("pincode")}
                      />
                      {formik.touched.pincode && formik.errors.pincode ? (
                        <div className="error">{formik.errors.pincode}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Address}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="address"
                        type="text"
                        placeholder="Please enter your address"
                        {...formik.getFieldProps("address")}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <div className="error">{formik.errors.address}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group-row">
                      <button type="submit" className="btn btn-submit">
                        {translations.SUBMIT}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {showModal && (
        <SuccessModal
          message={modalMessage}
          show={showModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default JoinAsCommittieMember;
