import React, { useEffect, useContext } from "react";
import Img from "../../assets/img/explore-our-mission.png";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import AOS from "aos";
import "aos/dist/aos.css";

const ExploreOurMission = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <>
      <Helmet>
        <title>Explore IMPC's Mission: Rejuvenating Temples Globally</title>
        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta
          name="description"
          content="IMPC revitalizes temples as hubs for social, economic, and cultural functions, providing a unified platform for their management and promoting the spirit of Sanatan Dharm."
        />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />

        <meta
          property="og:title"
          content="Explore IMPC's Mission: Rejuvenating Temples Globally"
        />
        <meta
          property="og:description"
          content="IMPC revitalizes temples as hubs for social, economic, and cultural functions, providing a unified platform for their management and promoting the spirit of Sanatan Dharm."
        />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.impc.co.in/explore-our-mission"
        />
        <meta property="og:type" content="website" />

        <link
          rel="canonical"
          href="https://www.impc.co.in/explore-our-mission"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <section className="container-fluid ExploreOurMission-banner">
        <div className="container mt-5">
          <div className="heading-wrapper text-center" data-aos="fade-down">
            <h1>{translations.ExploreOurMissionTittle}</h1>
            <div className="line-with-dots mb-3">
              <div className="dot"></div>
              <div className="line"></div>
              <div className="dot"></div>
            </div>
          </div>

          <div className="row gx-0">
            {/* Section 1 */}
            <div
              className="col-md-6 d-flex align-items-stretch explore-text"
              data-aos="zoom-out-down"
            >
              <div className="d-flex flex-column justify-content-start text-start p-4">
                <div className="ExploreOurMission-content">
                  <p>
                   {translations.ExploreOurMissionDescription_1}
                  </p>
                  <p>
                  {translations.ExploreOurMissionDescription_2}
                  </p>
                  <h5>{translations.ExploreOurMissionIMPCCommitteesTittle}</h5>
                  <ul>
                    <li>{translations.ExploreOurMissionIMPCCommittee_1}</li>
                    <li>{translations.ExploreOurMissionIMPCCommittee_2}</li>
                    <li>{translations.ExploreOurMissionIMPCCommittee_3}</li>
                    <li>{translations.ExploreOurMissionIMPCCommittee_4}</li>
                    <li>{translations.ExploreOurMissionIMPCCommittee_5}</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Section 2 */}
            <div
              className="col-md-6 d-flex align-items-stretch explore-img"
              data-aos="zoom-out-down"
            >
              <div className="d-flex justify-content-center align-items-center p-4">
                <img
                  src={Img}
                  alt="rejuvenate and augment the temples | Hindu Temple |IMPC"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreOurMission;
