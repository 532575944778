import React, { useEffect, useContext } from "react";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import AOS from "aos";
import "aos/dist/aos.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <div className="privacy-policy-banner">
      <div
        className="header-part  text-center py-3 container"
        data-aos="fade-down"
      >
        <h1>{translations.PrivacyPolicyTittle}</h1>
      </div>
      <div className="container py-5" data-aos="fade-down">
        <div className="privacy-policy-content">
          <h4>{translations.PrivacyPolicyHeadding_1} </h4>
          <p className="privacy-policy-content-description">{translations.PrivacyPolicyDescription_1}</p>

          <h4>{translations.PrivacyPolicyHeadding_2} </h4>
          <p>{translations.PrivacyPolicyDescription_2}:</p>
          <p>
            <strong> {translations.PrivacyPolicyHeaddingSub}: </strong>{" "}
            {translations.PrivacyPolicyDescription_3}
          </p>
          <p>
            <strong>{translations.PrivacyPolicyHeaddingSub_1}:</strong>
            {translations.PrivacyPolicyDescription_4}
          </p>
          <h4>{translations.PrivacyPolicyHeadding_3}</h4>
          <p>{translations.PrivacyPolicyDescription_5}:</p>

          <p>{translations.PrivacyPolicyDescription_6}</p>
          <p>{translations.PrivacyPolicyDescription_7}</p>
          <p>{translations.PrivacyPolicyDescription_8}</p>
          <p>{translations.PrivacyPolicyDescription_9}</p>

          <h4>{translations.PrivacyPolicyHeadding_4}</h4>
          <p>{translations.PrivacyPolicyDescription_10}:</p>
          <p>
            <strong> {translations.PrivacyPolicyHeaddingSub_2}:</strong>{" "}
            {translations.PrivacyPolicyDescription_11}
          </p>

          <p>
            <strong>{translations.PrivacyPolicyHeaddingSub_3}: </strong>
            {translations.PrivacyPolicyDescription_12}
          </p>

          <h4>{translations.PrivacyPolicyHeadding_5}</h4>
          <p>{translations.PrivacyPolicyDescription_13}</p>

          <h4>{translations.PrivacyPolicyHeadding_6}</h4>
          <p>
            <strong>{translations.PrivacyPolicyHeaddingSub_4}:</strong>
          </p>
          <p>{translations.PrivacyPolicyDescription_15}</p>
          <p>{translations.PrivacyPolicyDescription_16}</p>
          <p>{translations.PrivacyPolicyDescription_17}</p>
          <p>
            {translations.PrivacyPolicyDescription_18}
            <a href="mailto:info@impc.co.in">[info@impc.co.in]</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
