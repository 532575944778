import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../useContext/LanguageContext";
import enTranslations from "../assets/language/en.json";
import frTranslations from "../assets/language/hn.json";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import "./Footer.css";
import logoFooter from "../assets/img/client/footer-logo.png";
// import shankhanaad from "./path-to-shankhanaad.mp3";

const Footer = () => {
  //   useEffect(() => {
  //     if (localStorage.getItem("popState") !== "play") {
  //       const audioElement = new Audio(shankhanaad);
  //       audioElement.play();
  //       localStorage.setItem("popState", "play");
  //     }
  //   }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <>
      <footer className="footer-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-5" data-aos="fade-up">
              <div className="f-title">{translations.FooterQuickLinks}</div>
              <div className="f-menu">
                <ul>
                  <li>
                    <Link to="/explore-our-mission">
                      {translations.FooterExploreOurMission}
                    </Link>
                  </li>
                  <li>
                    <Link to="/heritage-developement">
                      {
                        translations.FooterTempleDevelopmentAndPreservationCommittee
                      }
                    </Link>
                  </li>
                  <li>
                    <Link to="/community-upliftment">
                      {translations.FooterCommunityUpliftment}
                    </Link>
                  </li>
                  <li>
                    <Link to="/vikas-committee">
                      {translations.FooterDevelopmentCommitteeForTempleWorkers}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bharat-jal-sanrakshan-committee">
                      {translations.FooterBharatJalSanrakshanCommittee}
                    </Link>
                  </li>
                  <li>
                    <Link to="/shakti-bharat-mission">
                      {translations.FooterShaktiBharatMission}
                    </Link>
                  </li>
                  <li>
                    <Link to="/adopt-now">{translations.FooterAdoptNow}</Link>
                  </li>
                  <li>
                    <Link to="/donation">{translations.FooterDonation}</Link>
                  </li>
                  <li>
                    <Link to="/join-as-a-volunteer">
                      {translations.FooterJoinAsAVolunteer}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{translations.FooterContactUs}</Link>
                  </li>

                  <li>
                    <Link to="/privacy-policy">
                      {translations.FooterPrivacyPolicy}
                    </Link>
                  </li>

                  <li>
                    <Link to="/terms-and-conditions">
                      {translations.FooterTermsAndConditions}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-5" data-aos="fade-up">
              <div className="f-logo">
                <img src={logoFooter} alt="IMPC Logo" />
              </div>
              {/* <div className="s-icon f-social-media-links">
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/impcofficialcouncil/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/impc.india/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCve4eTx7cCmPDryTLxJnDpw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/IMPC12"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-md-3" data-aos="fade-up">
              <div className="f-title">{translations.FooterGetInTouch}</div>
              <ul className="f-address">
                <li>
                  {
                    translations.FooterGetInTouchContactInternationalMandirPrabhandhakCouncilIMPCOffice
                  }
                </li>
                <li>
                  {
                    translations.FooterGetInTouchGurgaon12P1Tower7TakshilaHeightsSector37CGurugramHaryana
                  }
                </li>
                <li>
                  <strong>{translations.FooterGetInTouchConnectWithUs}</strong>:
                  <Link to="mailto:info@impc.co.in">
                    {translations.FooterGetInMail}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="copyright-banner">
          <div className="copyright">
            {translations.FooterCopyRight} {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
