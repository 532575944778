import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../pages/Home/Home";
import ContactUs from "../pages/ContactUs/ContactUs";
import { LanguageProvider } from "../useContext/LanguageContext";
import { Route, Routes, Navigate } from "react-router-dom";
import JoinAsCommittieMember from "../pages/GetInvolved/JoinAsCommittieMember";
import JoinAsADonor from "../pages/GetInvolved/JoinAsADonor";
import JoinAsAVolunteer from "../pages/GetInvolved/JoinAsAVolunteer";
import Donation from "../pages/Donation/Donation";
import ExploreOurMission from "../pages/ExploreOurMission/ExploreOurMission";
import AdoptNow from "../pages/AdoptNow/AdoptNow";
import HeritageDevelopmentCommittee from "../pages/Committees/HeritageDevelopmentCommittee";
import CommunityUpliftmentCommittee from "../pages/Committees/CommunityUpliftmentCommittee";
import VikasCommittee from "../pages/Committees/VikasCommittee";
import BharatJalSanrakshanCommittee from "../pages/Committees/BharatJalSanrakshanCommittee";
import ShaktiBharatMissionCommitte from "../pages/Committees/ShaktiBharatMissionCommitte";
import PrivacyPolicy from "../pages/Policies/PrivacyPolicy";
import TermsAndConditions from "../pages/Policies/TermsAndConditions";
import AboutUs from "../pages/AboutUs/AboutUs";
import AdoptACowForm from "../pages/AdoptNow/AdoptACowForm";
import AdoptATempleForm from "../pages/AdoptNow/AdoptATempleForm";
import AdoptATreeForm from "../pages/AdoptNow/AdoptATreeForm";
import OurTeam from "../pages/OurTeam/OurTeam";

const AppRoutes = () => {
  return (
    <div>
      <LanguageProvider>
        <Header />
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route
              path="/explore-our-mission"
              element={<ExploreOurMission />}
            />
            <Route path="/adopt-now" element={<AdoptNow />} />
            <Route path="/adopt-a-cow" element={<AdoptACowForm />} />
            <Route path="/adopt-a-tree" element={<AdoptATreeForm />} />
            <Route path="/adopt-a-temple" element={<AdoptATempleForm />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/join-as-a-volunteer" element={<JoinAsAVolunteer />} />
            <Route path="/join-as-a-donar" element={<JoinAsADonor />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route
              path="/join-as-a-committie-member"
              element={<JoinAsCommittieMember />}
            />
            <Route path="/donation" element={<Donation />} />
            <Route
              path="/heritage-developement"
              element={<HeritageDevelopmentCommittee />}
            />
            <Route
              path="/community-upliftment"
              element={<CommunityUpliftmentCommittee />}
            />
            <Route path="/vikas-committee" element={<VikasCommittee />} />
            <Route
              path="/bharat-jal-sanrakshan-committee"
              element={<BharatJalSanrakshanCommittee />}
            />
            <Route
              path="/shakti-bharat-mission"
              element={<ShaktiBharatMissionCommitte />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
          </Route>
        </Routes>
        <Footer />
      </LanguageProvider>
    </div>
  );
};

export default AppRoutes;
