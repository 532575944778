import React from "react";
import { Modal, Button } from "react-bootstrap";
import scucess from "../assets/img/success.png";
import error from "../assets/img/404-error.png";

const PaymentSuccessModel = ({ message, show, onClose, details }) => {
  const errorMessages = [
    "There was an error submitting the form. Please try again.",
    "Payment failed. Please try again.",
  ];

  // Check if the message is an error message
  const isError = errorMessages.includes(message);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="text-center">
        <h5>{isError ? "Error" : "Success"}</h5>
        <img
          src={isError ? error : scucess}
          alt={isError ? "Error" : "Success"}
          style={{ width: "60px", height: "60px", marginBottom: "10px" }}
        />
        <p>{message}</p>
        {!isError && (
          <div className="payment-details">
            <h6 className="title">Payment Details</h6>
            <div className="details-grid">
              <div className="detail-item">
                <strong>Name:</strong> {details?.data?.f_name}
                {details?.data?.l_name}
              </div>
              <div className="detail-item">
                <strong>Email:</strong> {details?.data?.mail}
              </div>
              <div className="detail-item">
                <strong>Payment ID:</strong> {details?.data?.payment_id}
              </div>
              <div className="details-row">
                <div className="detail-item">
                  <strong>Mobile:</strong> {details?.data?.mobile}
                </div>
                <div className="detail-item">
                  <strong>Amount Paid:</strong> {details?.data?.amount}
                </div>
              </div>
            </div>
          </div>
        )}
        {isError && (
          <div className="payment-details">
            <h6 className="title">Failed Payment Details</h6>
            <div className="details-grid">
              <div className="detail-item">
                <strong>Transaction ID:</strong>{" "}
                {details.transactionId || "----"}
              </div>
              <div className="detail-item">
                <strong>If you have any questions, please:</strong>
                <a href="/contact">Contact Us</a>.
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-submit" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentSuccessModel;
