import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../useContext/LanguageContext";
import aboutImg from "../../assets/img/about-img.png";
import { Link } from "react-router-dom";
import OurInspiration1 from "../../assets/img/Sri Hastamalakacharya 2.png";
import OurInspiration2 from "../../assets/img/client/swami-vivekanand.png";
import OurInspiration3 from "../../assets/img/Sri Sureshwaracharya.png";
import OurInspiration4 from "../../assets/img/Sri Padmapadacharya.png";
import OurInspiration5 from "../../assets/img/Sri Totakacharya.png";
import { Helmet } from "react-helmet";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImage1 from "../../assets/img/slider-temple-1.jpg";
import sliderImage2 from "../../assets/img/slider-temple-2.jpg";
import sliderImage3 from "../../assets/img/slider-temple-3.jpg";
import sliderImage4 from "../../assets/img/slider-temple-4.jpg";
import sliderImage5 from "../../assets/img/slider-temple-5.jpg";
import sliderImage6 from "../../assets/img/slider-temple-6.jpg";
import sliderImage7 from "../../assets/img/slider-temple-7.jpg";
import sliderImage8 from "../../assets/img/slider-temple-8.jpg";
import Team1 from "../../assets/img/Team/Mahipal Bhai.png";
import Team2 from "../../assets/img/Team/Kapil Mishra.png";
import Team3 from "../../assets/img/Team/Rajesh Yadav.png";
import Team4 from "../../assets/img/Team/Prabal Pratap Singh Tomar 1.png";
import Team5 from "../../assets/img/Team/Deep Sihag.png";
import Team6 from "../../assets/img/Team/Jagriti Motwani.png";
import Team7 from "../../assets/img/Team/Rohan Mahajan.png";
import Team8 from "../../assets/img/Team/Damini Vashisht.png";
import Team9 from "../../assets/img/Team/Pawan Sindhi.png";
import Team10 from "../../assets/img/Team/Manu-Punia-1.png";

const Home = () => {
  // const [language, setLanguage] = useState("en");

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  // Custom Next Arrow
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "gray",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };

  // Custom Previous Arrow
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "gray",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />, // Add custom next arrow
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 50,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>IMPC Mission: Revitalizing Temples & Cultural Heritage</title>
        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta
          name="description"
          content="IMPC aims to rejuvenate Indian temples and cultural sites through community, enterprise, and government efforts, focusing on heritage, upliftment, and sustainability."
        />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />

        <meta
          property="og:title"
          content="IMPC Mission: Revitalizing Temples & Cultural Heritage"
        />
        <meta
          property="og:description"
          content="IMPC aims to rejuvenate Indian temples and cultural sites through community, enterprise, and government efforts, focusing on heritage, upliftment, and sustainability."
        />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta property="og:url" content="https://www.impc.co.in/" />
        <meta property="og:type" content="website" />

        <link rel="canonical" href="https://www.impc.co.in/" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      {/* banner section start */}
      {/* <section
        className="bsb-hero-1 px-3"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "50%, 50%",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "flex-end",
          minHeight: "100vh",
        }}
      >
        <div className="container">
          <div className="row justify-content-md-center align-items-end">
            <div className="col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7 text-center">
              <div className="home-banner-content">
                <h2
                  className="display-5 text-white fw-bold mb-2"
                  data-aos="fade-up"
                >
                  {translations.bannerTitle}
                </h2>
                <p className="lead text-white mb-4" data-aos="fade-up">
                  {translations.bannerDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="carousel-container">
        <div className="carousel-slider">
          <Slider {...settings}>
            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage2})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage3})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage5})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage6})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage7})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>

            <div>
              <div
                className="fill"
                style={{
                  backgroundImage: `url(${sliderImage8})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="carousel-caption">
                  <h2>{translations.bannerTitle}</h2>
                  <p>{translations.bannerDescription}</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* banner section end */}

      {/* about section start */}

      <section id="about-us" className="container-fluid about-us-banner py-5">
        <div className="heading-wrapper text-center mb-4" data-aos="fade-down">
          <h1 className="heading-text">{translations.aboutUs}</h1>
          <div className="line-with-dots d-flex justify-content-center align-items-center mt-2">
            <div className="dot"></div>
            <div className="line mx-2"></div>
            <div className="dot"></div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-6 d-flex align-items-center mb-4 mb-md-0"
            data-aos="fade-down"
          >
            <img
              src={aboutImg}
              alt="heritage restoration in India | Temple Front View |IMPC"
              className="img-fluid"
            />
          </div>
          <div
            className="col-md-6 d-flex flex-column justify-content-center"
            data-aos="fade-down"
          >
            <p data-aos="fade-down" className="mb-4">
              <strong>{translations.ourMissionTitle}</strong> <br />
              {translations.ourMissionDescription}
            </p>
            <p><strong>{translations.IMPCcommittees}</strong></p>
            <ul>
              <li>{translations.CommitteesList1}</li>
              <li>{translations.CommitteesList2}</li>
              <li>{translations.CommitteesList3}</li>
              <li>{translations.CommitteesList4}</li>
              <li>{translations.CommitteesList5}</li>
            </ul>
            <Link to="/about-us">
              <button className="btn btn-submit">
                {translations.ReadMore}
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* about section end */}

      {/* OUR INSPIRATION section start here  */}

      {/* OUR INSPIRATION section end here  */}

      <section className="container-fluid our-inspiration-banner py-5">
        <div className="heading-wrapper text-center mb-4" data-aos="fade-down">
          <h4>{translations.ourInspirationTitle}</h4>
          <div className="line-with-dots mb-3">
            <div className="dot"></div>
            <div className="line"></div>
            <div className="dot"></div>
          </div>
        </div>
        <div className="container text-center our-inspiration-description">
          <p className="text-center mb-4" data-aos="fade-down">
            {translations.ourInspirationDescription}
          </p>
        </div>

        <div className="container main-card-inspiration">
          <div className="row">
            <Slider {...settings1}>
              <div className="col-12 col-md-4 inspiration-section">
                <div className="inspiration-card">
                  <img
                    src={OurInspiration1}
                    alt="Luna John"
                    className="inspiration-image card-img-inspiration"
                  />
                  <div className="inspiration-content">
                    <h4 className="inspiration-title">
                      {translations.ourInspirationCard1Title}
                    </h4>
                    <p className="inspiration-text">
                      {translations.ourInspirationCard1Description}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 inspiration-section">
                <div className="inspiration-card mb-5">
                  <img
                    src={OurInspiration2}
                    alt="Luna John"
                    className="inspiration-image"
                  />
                  <div className="inspiration-content">
                    <h4 className="inspiration-title">
                      {translations.ourInspirationCard2Title}
                    </h4>
                    <p className="inspiration-text">
                      {translations.ourInspirationCard2Description}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 inspiration-section">
                <div className="inspiration-card mb-5">
                  <img
                    src={OurInspiration3}
                    alt="Luna John"
                    className="inspiration-image card-img-inspiration"
                  />
                  <div className="inspiration-content">
                    <h4 className="inspiration-title">
                      {translations.ourInspirationCard3Title}
                    </h4>
                    <p className="inspiration-text">
                      {translations.ourInspirationCard3Description}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 inspiration-section">
                <div className="inspiration-card mb-5">
                  <img
                    src={OurInspiration4}
                    alt="Luna John"
                    className="inspiration-image card-img-inspiration"
                  />
                  <div className="inspiration-content">
                    <h4 className="inspiration-title">
                      {translations.ourInspirationCard4Title}
                    </h4>
                    <p className="inspiration-text">
                      {translations.ourInspirationCard4Description}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 inspiration-section">
                <div className="inspiration-card mb-5">
                  <img
                    src={OurInspiration5}
                    alt="Luna John"
                    className="inspiration-image card-img-inspiration"
                  />
                  <div className="inspiration-content">
                    <h4 className="inspiration-title">
                      {translations.ourInspirationCard5Title}
                    </h4>
                    <p className="inspiration-text">
                      {translations.ourInspirationCard5Description}
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* our Team section start */}

      <section className="container-fluid our-team-banner py-4">
        <div className="heading-wrapper text-center mb-4" data-aos="fade-down">
          <h4>Our Team</h4>
          <div className="line-with-dots mb-3">
            <div className="dot"></div>
            <div className="line"></div>
            <div className="dot"></div>
          </div>
        </div>

        <div className="our-section">
          <Slider {...settings2}>
            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team1} alt="Team1" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson1Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson1Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team9} alt="Team9" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson4Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson6Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team3} alt="Team3" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson3Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson3Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team2} alt="Team2" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson2Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson2Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team5} alt="Team5" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson5Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson5Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team4} alt="Team4" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson6Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson6Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team6} alt="Team6" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson7Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson7Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team7} alt="Team7" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson8Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson8Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team8} alt="Team8" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson9Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson9Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/our-team" className="card-wrapper">
              <div className="card-wrapper">
                <div className="card h-100 w-100">
                  <img src={Team10} alt="Team8" className="home-team-img" />
                  <div className="card-body">
                    <h3>{translations.ourTeamPreson10Name}</h3>
                    <p className="positionOurTeam">
                      {translations.ourTeamPreson10Title}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </Slider>
        </div>
      </section>

      {/* our Team section end */}

      {/* our services section start */}

      {/* <section className="container-fluid our-services-banner py-5">
        <div className="heading-wrapper" data-aos="fade-down">
          <h4 className="text-center">OUR SERVICES</h4>
          <div className="line-with-dots mb-3">
            <div className="dot"></div>
            <div className="line"></div>
            <div className="dot"></div>
          </div>
        </div>
        <p className="text-center mb-4" data-aos="fade-down">
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown <br /> printer took a galley of type and
          scrambled it to make a type...
        </p>

        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex flex-column align-items-end">
              <div
                className="service-item d-flex justify-content-between align-items-center"
                data-aos="fade-right"
              >
                <div className="d-flex flex-column text-end">
                  <h4 className="mb-3">Pooja</h4>
                  <p>
                    since the 1500s, when an unknown printer type and scrambled
                    took a galley of it to make a type...
                  </p>
                </div>
                <i className="fa-regular fa-star"></i>
              </div>
              <div
                className="service-item d-flex justify-content-between align-items-center"
                data-aos="fade-right"
              >
                <div className="d-flex flex-column text-end">
                  <h4 className="mb-3">Marriage</h4>
                  <p>
                    since the 1500s, when an unknown printer type and scrambled
                    took a galley of it to make a type...
                  </p>
                </div>
                <i className="fa-regular fa-star"></i>
              </div>
              <div
                className="service-item d-flex justify-content-between align-items-center"
                data-aos="fade-right"
              >
                <div className="d-flex flex-column text-end">
                  <h4 className="mb-3">Bhoomi Pooja</h4>
                  <p>
                    since the 1500s, when an unknown printer type and scrambled
                    took a galley of it to make a type...
                  </p>
                </div>
                <i className="fa-regular fa-star"></i>
              </div>
            </div>

            <div
              className="col-md-4 d-flex flex-column align-items-center justify-content-between"
              data-aos="fade-down"
            >
              <div
                className="d-flex flex-column align-items-center justify-content-center flex-grow-1"
                data-aos="fade-down"
              >
                <img src={Logo} alt="logo" className="mb-3" />
              </div>
              <button className="btn btn-orange mt-3 mb-3" data-aos="fade-down">
                Know More
              </button>
            </div>

            <div className="col-md-4 d-flex flex-column align-items-start">
              <div
                className="service-item d-flex justify-content-between align-items-center"
                data-aos="fade-left"
              >
                <i className="fa-regular fa-star"></i>
                <div className="d-flex flex-column text-start">
                  <h4 className="mb-3">Darshan</h4>
                  <p>
                    since the 1500s, when an unknown printer type and scrambled
                    took a galley of it to make a type...
                  </p>
                </div>
              </div>
              <div
                className="service-item d-flex justify-content-between align-items-center"
                data-aos="fade-left"
              >
                <i className="fa-regular fa-star"></i>
                <div className="d-flex flex-column text-start">
                  <h4 className="mb-3">Vehicle pooja</h4>
                  <p>
                    since the 1500s, when an unknown printer type and scrambled
                    took a galley of it to make a type...
                  </p>
                </div>
              </div>
              <div
                className="service-item d-flex justify-content-between align-items-center"
                data-aos="fade-left"
              >
                <i className="fa-regular fa-star"></i>
                <div className="d-flex flex-column text-start">
                  <h4 className="mb-3">Prashad</h4>
                  <p>
                    since the 1500s, when an unknown printer type and scrambled
                    took a galley of it to make a type...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* our services section end */}

      {/* recent events section start */}

      {/* <section className="container-fluid recent-events-banner py-5">
        <h4 className="text-center mb-4" data-aos="fade-up">
          recent events
        </h4>
        <p className="text-center mb-4" data-aos="fade-up">
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type ..
        </p>

        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-4" data-aos="zoom-in">
              <div className="card border-0">
                <img
                  src={CardImg1}
                  className="card-img-top"
                  alt="Event Image"
                />
                <div className="card-body">
                  <h5 className="card-title">Yatra</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-link text-primary">10am - 12pm</p>
                    <i className="fas fa-clock" aria-hidden="true"></i>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-link text-primary">Madhapur Road No.3</p>
                    <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  </div>
                  <p className="card-text">
                    Minim dolor in amet nulla laboris enim dolore consequat
                    proident fugiat culpa eiusmod proident sed excepteur
                    excepteur magna irure ex officia ea sunt in incididunt.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4" data-aos="zoom-in">
              <div className="card border-0">
                <img
                  src={CardImg2}
                  className="card-img-top"
                  alt="Event Image"
                />
                <div className="card-body">
                  <h5 className="card-title">Yatra</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-link text-primary">10am - 12pm</p>
                    <i className="fas fa-clock" aria-hidden="true"></i>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-link text-primary">Madhapur Road No.3</p>
                    <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  </div>
                  <p className="card-text">
                    Minim dolor in amet nulla laboris enim dolore consequat
                    proident fugiat culpa eiusmod proident sed excepteur
                    excepteur magna irure ex officia ea sunt in incididunt.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4" data-aos="zoom-in">
              <div className="card border-0">
                <img
                  src={CardImg3}
                  className="card-img-top"
                  alt="Event Image"
                />
                <div className="card-body">
                  <h5 className="card-title">Yatra</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-link text-primary">10am - 12pm</p>
                    <i className="fas fa-clock" aria-hidden="true"></i>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-link text-primary">Madhapur Road No.3</p>
                    <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  </div>
                  <p className="card-text">
                    Minim dolor in amet nulla laboris enim dolore consequat
                    proident fugiat culpa eiusmod proident sed excepteur
                    excepteur magna irure ex officia ea sunt in incididunt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* recent events section end */}

      {/* contact us section start  */}

      {/* <section className="container-fluid contact-banner py-5">
        <h4 className="text-center mb-4" data-aos="fade-down">
          Contact Us
        </h4>
        <p className="text-center mb-4" data-aos="fade-down">
          We're always here to answer any questions and provide further
          information. Reach out to us at:
        </p>

        <div className="container contact-container">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center">
              <form action="#" className="contact-form">
                <p className="description">
                  Feel free to contact us if you need any assistance, any help
                  or another question.
                </p>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="Subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    id="message"
                    className="form-control"
                    rows="5"
                    placeholder="Message"
                    required
                    style={{ resize: "none" }}
                  ></textarea>
                </div>
                <div className="submit-button-wrapper">
                  <input
                    type="submit"
                    value="Send"
                    className="btn btn-orange"
                  />
                </div>
              </form>
            </div>

            <div className="col-md-6">
              <div className="support-contact">
                <div className="support-info">
                  <h5 className="mb-4">Support Contact</h5>
                  <div className="row">
                    <div
                      className="col-md-5 support-column"
                      data-aos="fade-up"
                      data-aos-anchor-placement="bottom-bottom"
                    >
                      <div className="support-item">
                        <i className="fa-solid fa-phone"></i>
                        <h6>Phone</h6>
                        <p>Mobile: (+91) 9272026881</p>
                        <p>Mobile: (+91) 9272026881</p>
                      </div>
                      <div className="support-item">
                        <i className="fa-regular fa-envelope"></i>
                        <h6>Email</h6>
                        <p>example@example.com</p>
                        <p>support@example.com</p>
                      </div>
                    </div>

                    <div className="col-md-1 d-flex align-items-center justify-content-center">
                      <div className="vertical-divider"></div>
                    </div>

                    <div
                      className="col-md-5 support-column"
                      data-aos="fade-up"
                      data-aos-anchor-placement="bottom-bottom"
                    >
                      <div className="support-item">
                        <i className="fa-solid fa-location-dot"></i>
                        <h6>Location</h6>
                        <p>5-65, Mdpr, Hyd, T.S</p>
                      </div>
                      <div className="support-item">
                        <i className="fa-regular fa-clock"></i>
                        <h6>Open Hours</h6>
                        <p>6AM to 6PM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* contact us section end  */}
    </>
  );
};

export default Home;
