import React from "react";
import { Modal, Button } from "react-bootstrap";
import scucess from "../assets/img/success.png";
import error from "../assets/img/404-error.png";

const SuccessModal = ({ message, show, onClose }) => {
  const errorMessages = [
    "There was an error submitting the form. Please try again.",
    "Payment failed. Please try again."
  ];
  // Check if the message is the error message
  const isError = errorMessages.includes(message);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="text-center">
        <h5>{isError ? "Error" : "Success"}</h5>
        <img
          src={isError ? error : scucess}
          alt={isError ? "Error" : "Success"}
          style={{ width: "120px", height: "120px", marginBottom: "20px" }}
        />
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-submit" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
