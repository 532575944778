import React from "react";

const Loader = ({active}) => {
  return (
    <div className={`main-loader ${active ? "active" : ""}`}>
    <div className="loader-content">
      <div className="loader"></div>
    </div>
  </div>
  );
};

export default Loader;
