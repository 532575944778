import React, { useState, useEffect, useCallback, useContext } from "react";
import SuccessModal from "../../components/SuccessModal";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../axiosInstance";
import Loader from "../../components/Loader";

const adoptACow = (payload) => {
  return axiosInstance.post(`/adoption`, payload);
};

const AdoptACowForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCustomQuantity, setShowCustomQuantity] = useState(false);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  const formik = useFormik({
    initialValues: {
      adoption_choice: "Cow",
      f_name: "",
      email: "",
      phone: "",
      obj_count: "",
      quote_count: "",
      numberOfCows: "5",
    },
    validationSchema: Yup.object({
      f_name: Yup.string().required("Name is required"),
      phone: Yup.string()
        .required("Mobile Number is required")
        .matches(/^[0-9]{10}$/, "Must be exactly 10 digits"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      // Prepare amount based on the number of cows
      const numberOfCows =
        values.numberOfCows === "custom"
          ? parseInt(values.quote_count)
          : parseInt(values.numberOfCows);
      const amount = numberOfCows * 100; // Each cow costs 100

      // Trigger payment
      handlePayment(amount, values);
    },
  });

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setShowCustomQuantity(value === "custom");
    formik.setFieldValue("numberOfCows", value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePayment = useCallback((amount, formValues) => {
    const options = {
      //key: "rzp_test_zmdDxQzJkadwMA", // test key
      key: "rzp_live_wanb2nCfu7zO2t", // live Key
      amount: amount * 100, // amount in paise
      currency: "INR",
      name: "IMPC",
      description: "One Stop solution for Devotion",
      image: "https://bhagvabucket.s3.amazonaws.com/logo.png",
      handler: async (response) => {
        console.log(response);

        // Prepare payload for API call after successful payment
        const payload = {
          adoption_choice: formValues.adoption_choice,
          f_name: formValues.f_name,
          email: formValues.email,
          phone: formValues.phone,
          payment_id: response.razorpay_payment_id,
          obj_count:
            formValues.numberOfCows === "custom"
              ? undefined
              : formValues.numberOfCows,
          quote_count:
            formValues.numberOfCows === "custom"
              ? formValues.quote_count
              : undefined,
        };

        // Call the API to adopt a cow
        try {
          const apiResponse = await adoptACow(payload);
          console.log("Form submitted:", apiResponse.data);
          setModalMessage(
            "Thank you for your generous decision to adopt a cow; your kindness makes a world of difference!"
          );
          setShowModal(true);
          formik.resetForm();
        } catch (error) {
          console.error("Error submitting the form:", error);
          setModalMessage(
            "There was an error submitting the form. Please try again."
          );
          setShowModal(true);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new window.Razorpay(options);
    rzpay.on("payment.failed", (response) => {
      console.error("Payment failed:", response.error);
      setModalMessage("Payment failed. Please try again.");
      setShowModal(true);
    });
    rzpay.open();
  }, []);

  return (
    <>
      {loading && <Loader active={loading} />}
      <section className="getInvolved-banner">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="heading text-center">
                <h1 style={{ fontSize: "2.1rem" }}>
                  {translations.AdoptACowFormTittle}
                </h1>
                <p className="adopt-a-cow-from-tittle">
                  <strong> {translations.AdoptACowFormDescription_1}: </strong>
                  {translations.AdoptACowFormDescription_2}
                </p>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="getInvolved-contact-form mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Name}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="f_name"
                        type="text"
                        placeholder="Please enter name"
                        {...formik.getFieldProps("f_name")}
                        onKeyPress={(event) => {
                          if (!/^[a-zA-Z]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {formik.touched.f_name && formik.errors.f_name ? (
                        <div className="error">{formik.errors.f_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Email}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="email"
                        type="email"
                        placeholder="Please enter email"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.PhoneNumber}
                        <span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="phone"
                        type="tel"
                        placeholder="Please enter mobile number"
                        maxLength="10"
                        {...formik.getFieldProps("phone")}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="error">{formik.errors.phone}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group-row">
                        <label className="form-label">
                          {translations.NumberOfCows}
                          <span>*</span>
                        </label>
                        <div className="numberOfCows">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="numberOfCows"
                              value="5"
                              onChange={handleRadioChange}
                              checked={formik.values.numberOfCows === "5"}
                            />
                            <label className="form-check-label">5</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="numberOfCows"
                              value="10"
                              onChange={handleRadioChange}
                              checked={formik.values.numberOfCows === "10"}
                            />
                            <label className="form-check-label">10</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="numberOfCows"
                              value="50"
                              onChange={handleRadioChange}
                              checked={formik.values.numberOfCows === "50"}
                            />
                            <label className="form-check-label">50</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="numberOfCows"
                              value="100"
                              onChange={handleRadioChange}
                              checked={formik.values.numberOfCows === "100"}
                            />
                            <label className="form-check-label">100</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="numberOfCows"
                              value="custom"
                              onChange={handleRadioChange}
                              checked={formik.values.numberOfCows === "custom"}
                            />
                            <label className="form-check-label">
                              Choose Your Own Quantity
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {showCustomQuantity && (
                      <div className="col-md-6">
                        <div className="form-group-row">
                          <label className="form-label">
                            {translations.QuoteYourNumber}
                          </label>
                          <input
                            className="form-control form-field"
                            name="quote_count"
                            type="text"
                            placeholder="Please enter your custom number"
                            {...formik.getFieldProps("quote_count")}
                          />
                          {formik.touched.quote_count &&
                          formik.errors.quote_count ? (
                            <div className="error">
                              {formik.errors.quote_count}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="form-group-row">
                      <button type="submit" className="btn btn-submit">
                        {translations.SUBMIT}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {showModal && (
        <SuccessModal
          message={modalMessage}
          show={showModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default AdoptACowForm;
