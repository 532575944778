import React, { useEffect, useContext } from "react";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import AdoptATemple from "../../assets/img/AdoptaTemple.png";
import AdoptACow from "../../assets/img/AdoptaCow.png";
import AdoptATree from "../../assets/img/AdoptaTree.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const AdoptNow = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <>
      <Helmet>
        <title>
          Adopt a Temple, Cow, or Tree: Support Heritage & Sustainability
        </title>

        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta
          name="description"
          content="Join IMPC’s initiatives: Adopt a Temple to preserve heritage, a Cow for sustainable farming, or a Tree for afforestation. Contribute and receive updates and blessings"
        />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />
        <meta
          property="og:title"
          content="Adopt a Temple, Cow, or Tree: Support Heritage & Sustainability"
        />
        <meta
          property="og:description"
          content="Join IMPC’s initiatives: Adopt a Temple to preserve heritage, a Cow for sustainable farming, or a Tree for afforestation. Contribute and receive updates and blessings"
        />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta property="og:url" content="https://www.impc.co.in/adopt-now" />
        <meta property="og:type" content="website" />

        <link rel="canonical" href="https://www.impc.co.in/adopt-now" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <section
        className="bsb-hero-1 px-3 banner-gradient adopt-main-banner"
        // style={{
        //   backgroundImage: `url(${AdoptNowbanner})`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "50%, 50%",
        //   display: "flex",
        //   alignItems: "flex-end",
        //   minHeight: "100vh",
        // }}
      >
        <div className="container">
          <div className="row justify-content-md-center align-items-end">
            <div className="col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7 text-center">
              <h1
                className="display-5 text-white fw-bold mb-2"
                data-aos="fade-up"
              >
                {translations.AdoptNowBannerTittle}
              </h1>
              <p className="lead text-white mb-4" data-aos="fade-up">
                {translations.AdoptNowBannerDescription}:
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid AdoptNowbanner">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-6 col-12 mb-4" data-aos="zoom-in-up">
              <div className="temple h-100 text-center">
                <h4>{translations.AdoptATempleTittle}:</h4>
                <p className="text-start">
                  {translations.AdoptATempleDescription}
                </p>
                <img
                  src={AdoptATemple}
                  alt="AdoptATemple"
                  className="img-fluid"
                />
                <Link to="/adopt-a-temple" className="no-underline">
                  <button className="btn btn-submit">
                    {translations.AdoptButton}
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12 mb-4" data-aos="zoom-in-up">
              <div className="temple text-center">
                <h4>{translations.AdoptACowTittle}:</h4>
                <p className="text-start">
                  {translations.AdoptACowDescription}
                </p>
                <img
                  src={AdoptACow}
                  alt="AdoptACow"
                  className="img-fluid mt-3"
                />
                <Link to="/adopt-a-cow" className="no-underline">
                  <button className="btn btn-submit">
                    {translations.AdoptButton}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6 col-12" data-aos="zoom-in-up">
              <div className="temple text-center">
                <h4>{translations.AdoptATreeTittle}:</h4>
                <p className="text-start">
                  {translations.AdoptATreeDescription}
                </p>
                <img
                  src={AdoptATree}
                  alt="AdoptATree"
                  className="img-fluid mt-3"
                />
                <Link to="/adopt-a-tree" className="no-underline">
                  <button className="btn btn-submit">
                    {translations.AdoptButton}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdoptNow;
