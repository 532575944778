import React, { useEffect, useContext } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import HeritageDevelopmentImg1 from "../../assets/img/HeritageDevelopmentSlider-1.png";
import HeritageDevelopmentImg2 from "../../assets/img/HeritageDevelopmentSlider-2.png";
import HeritageDevelopmentImg3 from "../../assets/img/HeritageDevelopmentSlider-3.png";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const HeritageDevelopmentCommittee = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <>
      <Helmet>
        <title>
          Preserve Indian Heritage: Donate to Our Restoration Efforts
        </title>

        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta
          name="description"
          content="Join our committee to preserve Indian heritage. Contribute to site restoration, Yamuna River development, and carbon credit initiatives for cultural site funding."
        />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />

        <meta
          property="og:title"
          content="Preserve Indian Heritage: Donate to Our Restoration Efforts"
        />
        <meta
          property="og:description"
          content="Join our committee to preserve Indian heritage. Contribute to site restoration, Yamuna River development, and carbon credit initiatives for cultural site funding."
        />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.impc.co.in/heritage-developement"
        />
        <meta property="og:type" content="website" />

        <link
          rel="canonical"
          href="https://www.impc.co.in/heritage-developement"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <section
        className="bsb-hero-1 px-3 banner-gradient heritage-development-main-banner"
        // style={{
        //   backgroundImage: `url(${HeritageDevelopmentBanner})`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "50%, 50%",
        //   display: "flex",
        //   alignItems: "flex-end",
        //   minHeight: "110vh",
        // }}
      >
        <div className="container">
          <div className="row justify-content-md-center align-items-end">
            <div className="col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7 text-center">
              <h1
                className="display-6 text-white fw-bold mb-5"
                data-aos="fade-up"
              >
                {
                  translations.TempleDevelopmentAndPreservationCommitteeBannerTittle
                }
              </h1>
              {/* <p className="lead text-white mb-4" data-aos="fade-up">
                Heritage Development, Promotions and Preservation Committee
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="committee-section">
          {/* Text Content on the Left */}
          <div className="text-content" data-aos="zoom-out-down">
            <h4>
              {
                translations.TempleDevelopmentAndPreservationCommitteeDescription_1
              }
            </h4>
            <p className="mb-2">
              {
                translations.TempleDevelopmentAndPreservationCommitteeDescription_2
              }
            </p>
            <p className="mb-2">
              {
                translations.TempleDevelopmentAndPreservationCommitteeDescription_3
              }
            </p>

            <h4>
              {translations.TempleDevelopmentAndPreservationCommitteeListTittle}
            </h4>
            <div className="custom-font-size-list-text">
              <ul className="custom-font-size-list">
                <li>
                  {
                    translations.TempleDevelopmentAndPreservationCommitteeListTittleHeadding_1
                  }
                  <ul>
                    <li>
                      {
                        translations.TempleDevelopmentAndPreservationCommitteeList_1
                      }
                    </li>
                    <li>
                      {
                        translations.TempleDevelopmentAndPreservationCommitteeList_2
                      }
                    </li>
                    <li>
                      {
                        translations.TempleDevelopmentAndPreservationCommitteeList_3
                      }
                    </li>
                    <li>
                      {
                        translations.TempleDevelopmentAndPreservationCommitteeList_4
                      }
                    </li>
                  </ul>
                </li>
                <li>
                  {
                    translations.TempleDevelopmentAndPreservationCommitteeListTittleHeadding_2
                  }
                  <ul>
                    <li>
                      {
                        translations.TempleDevelopmentAndPreservationCommitteeList_5
                      }
                    </li>
                  </ul>
                </li>
              </ul>

              <ul>
                <li>
                  {
                    translations.TempleDevelopmentAndPreservationCommitteeListTittleHeadding_3
                  }
                  <ul>
                    <li>
                      {
                        translations.TempleDevelopmentAndPreservationCommitteeList_6
                      }
                    </li>
                    <li>
                      <li>
                        {
                          translations.TempleDevelopmentAndPreservationCommitteeList_7
                        }
                      </li>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul>
                <li>
                  {translations.TempleDevelopmentAndPreservationCommitteeList_8}
                </li>
                <li>
                  {translations.TempleDevelopmentAndPreservationCommitteeList_9}
                </li>
                <li>
                  {
                    translations.TempleDevelopmentAndPreservationCommitteeList_10
                  }
                </li>
                <li>
                  {
                    translations.TempleDevelopmentAndPreservationCommitteeList_11
                  }
                </li>
              </ul>
            </div>
            <h4>
              {
                translations.TempleDevelopmentAndPreservationCommitteeListTittleHeadding_4
              }
            </h4>
            <p>
              {
                translations.TempleDevelopmentAndPreservationCommitteeListDescription
              }
            </p>

            <div className="donation-button mb-5">
              <Link to="/donation">
                <button className="btn-submit">
                  {
                    translations.TempleDevelopmentAndPreservationCommitteeListButton
                  }
                </button>
              </Link>
            </div>
          </div>

          {/* Slider Content on the Right */}
          <div className="slider-content mb-5">
            <Slider {...sliderSettings}>
              <div>
                <img
                  src={HeritageDevelopmentImg1}
                  alt="Heritage restoration in India"
                  className="img-fluid"
                />
              </div>
              <div>
                <img
                  src={HeritageDevelopmentImg2}
                  alt="Heritage restoration in India"
                  className="img-fluid"
                />
              </div>
              <div>
                <img
                  src={HeritageDevelopmentImg3}
                  alt="Heritage restoration in India"
                  className="img-fluid"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeritageDevelopmentCommittee;
