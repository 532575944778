import React, { useState, useEffect, useCallback, useContext } from "react";
import Logo from "../../assets/img/client/logo.png";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import PaymentSuccessModal from "../../components/PaymentSuccessModel";
import { Helmet } from "react-helmet";
import { axiosInstance } from "../../axiosInstance";
import toast, { Toaster } from "react-hot-toast";

const addDonationMember = (payload) => {
  return axiosInstance.post(`/razorpay-response`, payload);
};

const Donation = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    amount: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [details, setDetails] = useState({});

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "firstName" || name === "lastName") {
      const regex = /^[a-zA-Z\s]*$/;
      if (regex.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      } else {
        toast.error("Only alphabetic characters are allowed in name fields.");
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.mobile ||
      !formData.email ||
      !formData.amount
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }

    // Validate mobile number (10 digits)
    if (!/^\d{10}$/.test(formData.mobile)) {
      toast.error("Please enter a valid 10-digit mobile number.");
      return;
    }

    const numericAmount = formData.amount.replace(/[^\d]/g, "");
    if (numericAmount && parseInt(numericAmount) > 0) {
      handlePayment();
    } else {
      toast.error("Please enter a valid donation amount.");
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handlePayment = useCallback(() => {
    const amountInPaise = parseInt(formData.amount.replace(/[^\d]/g, "")) * 100;

    const options = {
      //key: "rzp_test_zmdDxQzJkadwMA", // test key
      key: "rzp_live_wanb2nCfu7zO2t", // live Key
      amount: amountInPaise,
      currency: "INR",
      name: "IMPC",
      description: "One Stop solution for Devotion",
      image: "https://bhagvabucket.s3.amazonaws.com/logo.png",
      handler: async (response) => {
        console.log("Payment successful", response);

        try {
          // Wait for the API response and get the response object
          const apiResponse = await handlePaymentUserAPI(response);

          // Set details from the API response
          setDetails(apiResponse.data);

          setModalMessage("Your donation was successful. Thank you!");
          setShowModal(true);
        } catch (error) {
          console.error("Error during donation API call:", error);
          setModalMessage(
            "Payment successful but failed to process donation details. Please contact support."
          );
          setShowModal(true);
        }
      },
      prefill: {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        contact: formData.mobile,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new window.Razorpay(options);

    rzpay.on("payment.failed", (response) => {
      console.error("Payment failed:", response.error);
      setModalMessage("Payment failed. Please try again.");
      setShowModal(true);
    });

    rzpay.open();
  }, [formData]);

  // Function to handle the payment-user API call
  const handlePaymentUserAPI = async (paymentResponse) => {
    const payload = {
      f_name: formData.firstName,
      l_name: formData.lastName,
      mail: formData.email,
      amount: formData.amount,
      mobile: formData.mobile,
      payment_id: paymentResponse.razorpay_payment_id,
    };

    try {
      const response = await addDonationMember(payload);
      console.log("Donation API success:", response);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        amount: "",
      });
      return response; // Return the API response
    } catch (error) {
      console.error("Donation API error:", error);
      throw new Error("Failed to process donation details.");
    }
  };

  const formatNumber = (n) => {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");
    const formattedValue = formatNumber(numericValue);
    setFormData((prevData) => ({
      ...prevData,
      amount: formattedValue ? `₹${formattedValue}` : "",
    }));
  };

  const handleBlur = () => {
    if (formData.amount === "₹") {
      setFormData((prevData) => ({
        ...prevData,
        amount: "",
      }));
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={true} />
      <Helmet>
        <title>
          Make a Difference: Donate to Preserve Heritage & Empower Communities
        </title>
        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta
          name="description"
          content="Your donations support heritage preservation, community empowerment, and environmental sustainability. Every contribution has a meaningful impact."
        />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />
        <meta
          property="og:title"
          content="Make a Difference: Donate to Preserve Heritage & Empower Communities"
        />
        <meta
          property="og:description"
          content="Your donations support heritage preservation, community empowerment, and environmental sustainability. Every contribution has a meaningful impact."
        />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta property="og:url" content="https://www.impc.co.in/donation" />
        <meta property="og:type" content="website" />

        <link rel="canonical" href="https://www.impc.co.in/donation" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <section className="donation-banner">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="donation-heading">
                <img src={Logo} alt="logo" className="logo" />
                <p>
                  {translations.DonationDescription_1}
                 <br />
                 {translations.DonationDescription_2}
                </p>
                <p>
                  <strong> {translations.Note}:</strong> {translations.DonationDescription_3}
                </p>
                <h1>{translations.DonationFromTittle}</h1>
                <div className="line-with-dots-donation">
                  <div className="dot-donation"></div>
                  <div className="line-donation"></div>
                  <div className="dot-donation"></div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="Account-details text-center p-4">
                      <div>
                        <span> {translations.AccountName}: </span> {translations.InternationalMandirPrabandhakCouncil}
                      </div>
                      <div>
                        <span>{translations.AccountNumber}:</span> 00000065272997408
                      </div>
                      <div>
                        <span>{translations.BankName}: {translations.SBIIFSCCode}: </span>SBIN0050203
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="donation-form mb-5">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.FirstName}<span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="firstName"
                        type="text"
                        placeholder="Please enter first name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.LastName}<span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="lastName"
                        type="text"
                        placeholder="Please enter last name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.Email}<span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        type="email"
                        name="email"
                        placeholder="Please enter your email id"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                        {translations.MobileNo}<span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="mobile"
                        type="tel"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        placeholder="Please enter mobile number"
                        maxLength="10"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group-row">
                      <label className="form-label">
                       {translations.Amount}<span>*</span>
                      </label>
                      <input
                        className="form-control form-field"
                        name="amount"
                        type="text"
                        id="currency-field"
                        value={formData.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter the amount"
                        style={{
                          color: "#333",
                          fontSize: "16px",
                          marginBottom: "20px",
                          padding: ".5rem 1rem",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group-row">
                      <button type="submit" className="btn btn-submit">
                        {translations.PAY}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      {showModal && (
        <PaymentSuccessModal
          message={modalMessage}
          show={showModal}
          onClose={closeModal}
          details={details}
        />
      )}
    </>
  );
};

export default Donation;
