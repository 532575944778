import React, { useEffect, useContext } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import BharatJalSanrakshanCommitteeImg1 from "../../assets/img/BharatJalSanrakshanSlider-1.png";
import BharatJalSanrakshanCommitteeImg2 from "../../assets/img/BharatJalSanrakshanSlider-2.png";
import BharatJalSanrakshanCommitteeImg3 from "../../assets/img/BharatJalSanrakshanSlider-3.png";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

const BharatJalSanrakshanCommittee = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <>
      <Helmet>
        <title>Water Conservation: Protecting Future Generations</title>

        {/* Google Analytics Script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
        ></script>
        <script>
          {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
        </script>
        <meta
          name="description"
          content="Join us in water conservation efforts, including river cleaning, water harvesting, and flood prevention to secure a sustainable future."
        />
        <meta
          name="keywords"
          content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
        />

        <meta
          property="og:title"
          content="Water Conservation: Protecting Future Generations"
        />
        <meta
          property="og:description"
          content="Join us in water conservation efforts, including river cleaning, water harvesting, and flood prevention to secure a sustainable future."
        />
        <meta
          property="og:image"
          content="https://bhagvabucket.s3.amazonaws.com/logo.png"
        />
        <meta
          property="og:url"
          content="https://www.impc.co.in/bharat-jal-sanrakshan-committee"
        />
        <meta property="og:type" content="website" />

        <link
          rel="canonical"
          href="https://www.impc.co.in/bharat-jal-sanrakshan-committee"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <section
        className="bsb-hero-1 px-3 banner-gradient bharat-jal-sanrakshan-committee-banner"
        // style={{
        //   backgroundImage: `url(${BharatJalSanrakshanCommitteeBanner})`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "50%, 50%",
        //   display: "flex",
        //   alignItems: "flex-end",
        //   minHeight: "100vh",
        // }}
      >
        <div className="container">
          <div className="row justify-content-md-center align-items-end">
            <div className="col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7 text-center">
              <h1
                className="display-5 text-white fw-bold mb-5"
                data-aos="fade-up"
              >
                {translations.BharatJalSanrakshanCommitteeBannerTittle}
              </h1>
              {/* <p className="lead text-white mb-4" data-aos="fade-up">
                 Bharat Jal Sanrakshan Committee
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid Committee-inner-banner">
        <div className="container pt-5 pb-5 ">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12" data-aos="zoom-out-down">
              <h4 className="mb-4">
                {translations.BharatJalSanrakshanCommitteeTittle_1}
              </h4>
              <p className="mb-3">
                {translations.BharatJalSanrakshanCommitteeDescription}
              </p>
              <h4>{translations.BharatJalSanrakshanCommitteeListHeadding}</h4>
              <ul className="custom-font-size-list">
                <li className="mb2">
                  <strong>
                    {translations.BharatJalSanrakshanCommitteeList_Headding_1}:
                  </strong>
                  {" "}
                  {translations.BharatJalSanrakshanCommitteeList_Description_1}
                </li>
                <li className="mb2">
                  <strong>
                    {translations.BharatJalSanrakshanCommitteeList_Headding_2}:
                  </strong>
                  {" "}
                  {translations.BharatJalSanrakshanCommitteeList_Description_2}
                </li>
                <li className="mb2">
                  <strong>
                    {translations.BharatJalSanrakshanCommitteeList_Headding_3}:
                  </strong>
                  {" "}
                  {translations.BharatJalSanrakshanCommitteeList_Description_3}
                </li>
              </ul>
              <div className="d-flex justify-content-center">
                <Link to="/donation">
                  <button className="btn btn-submit">
                    {
                      translations.TempleDevelopmentAndPreservationCommitteeListButton
                    }
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 text-center mt-4 mt-lg-0">
              <Slider {...sliderSettings}>
                <img
                  src={BharatJalSanrakshanCommitteeImg1}
                  alt="VikasCommitteeImg"
                  className="img-fluid"
                />

                <img
                  src={BharatJalSanrakshanCommitteeImg2}
                  alt="VikasCommitteeImg"
                  className="img-fluid"
                />

                <img
                  src={BharatJalSanrakshanCommitteeImg3}
                  alt="VikasCommitteeImg"
                  className="img-fluid"
                />
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BharatJalSanrakshanCommittee;
