import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <>
      <div>
        <Helmet>
          <title>IMPC: Revitalizing Temples & Advancing SDGs in India</title>

          {/* Google Analytics Script */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-XX4VBTPB63"
          ></script>
          <script>
            {` 
          window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

           gtag("config", "G-XX4VBTPB63");
        `}
          </script>
          <meta
            name="description"
            content="IMPC rejuvenates Indian temples and cultural sites, advancing UN SDGs through poverty eradication, education, gender equality, and environmental sustainability."
          />
          <meta
            name="keywords"
            content="Indian temple restoration projects,Heritage restoration in India,Heritage site development programs,Protecting ancient temples and cultural sites,Funding heritage site restoration, Indian temples and cultural site restoration projects, Temple restoration volunteer programs, Volunteer for cultural site preservation in India,  Empowering women in heritage restoration, Jobs around Indian temples, Waste management solutions in cultural sites"
          />

          <meta
            property="og:title"
            content="IMPC: Revitalizing Temples & Advancing SDGs in India"
          />
          <meta
            property="og:description"
            content="IMPC rejuvenates Indian temples and cultural sites, advancing UN SDGs through poverty eradication, education, gender equality, and environmental sustainability."
          />
          <meta
            property="og:image"
            content="https://bhagvabucket.s3.amazonaws.com/logo.png"
          />
          <meta property="og:url" content="https://www.impc.co.in/about-us" />
          <meta property="og:type" content="website" />

          <link rel="canonical" href="https://www.impc.co.in/about-us" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>

        {/* banner section start */}
        <section
          class="bsb-hero-1 px-3 banner-gradient about-us-main-banner"
          // style={{
          //   backgroundImage: `url(${banner})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "50%, 50%",
          //   backgroundRepeat: "no-repeat",
          //   display: "flex",
          //   alignItems: "flex-end",
          //   minHeight: "100vh",
          // }}
        >
          <div className="container">
            <div className="row justify-content-md-center align-items-end">
              <div className="col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7 text-center">
                <h1
                  className="display-5 text-white fw-bold mb-2"
                  data-aos="fade-up"
                >
                  {translations.aboutUsbannerTittle}
                </h1>
                <p className="lead text-white mb-4" data-aos="fade-up">
                  {translations.aboutUsbannerDescription}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="container-fluid About-us-inner-banner py-5"
          data-aos="fade-down"
        >
          <div className="container">
            <h3>{translations.aboutUsMissionStatementTittle}</h3>
            <p>{translations.aboutMissionStatementDescription_1}</p>
            <p>{translations.aboutMissionStatementDescription_2}</p>
            <h5>{translations.aboutUsIMPCCommittees}:</h5>
            <ul>
              <li>{translations.aboutUsIMPCCommitteesList_1}</li>
              <li>{translations.aboutUsIMPCCommitteesList_2}</li>
              <li>{translations.aboutUsIMPCCommitteesList_3}</li>
              <li>{translations.aboutUsIMPCCommitteesList_4}</li>
              <li>{translations.aboutUsIMPCCommitteesList_5}</li>
            </ul>
            <p>{translations.aboutImpcCommitteesListText}:</p>

            <div className="about-us-content">
              <h4>{translations.aboutImpcCommittesSectionHeadding_1}</h4>
              <p>{translations.aboutImpcCommittesSectionDescription_1}</p>
              <h4>{translations.aboutImpcCommittesSectionHeadding_2} </h4>
              <p>{translations.aboutImpcCommittesSectionDescription_2}</p>
              <h4>{translations.aboutImpcCommittesSectionHeadding_3} </h4>
              <p>{translations.aboutImpcCommittesSectionDescription_3}</p>
              <h4>{translations.aboutImpcCommittesSectionHeadding_4}</h4>
              <p>{translations.aboutImpcCommittesSectionDescription_4}</p>
              <h4>{translations.aboutImpcCommittesSectionHeadding_5}</h4>
              <p>{translations.aboutImpcCommittesSectionDescription_5}</p>
              <h4>{translations.aboutImpcCommittesSectionHeadding_6} </h4>
              <p>{translations.aboutImpcCommittesSectionDescription_6}</p>
              <h4>{translations.aboutImpcCommittesSectionHeadding_7}</h4>
              <p>{translations.aboutImpcCommittesSectionDescription_7}</p>
              <h4>{translations.aboutImpcCommittesSectionHeadding_8}</h4>
              <p>{translations.aboutImpcCommittesSectionDescription_8}</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
