import React, { useState, useContext } from "react";
import {
  Link,
  Link as RouterLink,
  useLocation,
  NavLink,
} from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../assets/img/client/logo.png";
import { LanguageContext } from "../useContext/LanguageContext";
import enTranslations from "../assets/language/en.json";
import frTranslations from "../assets/language/hn.json";
import youtube from "../assets/img/youtube_logo.png";
import facebook from "../assets/img/facebook_logo.png";
import insta from "../assets/img/insta_logo.png";
import twitter from "../assets/img/twitter_logo.png";
import linkedin from "../assets/img/linkedin-icon.png";
import Modal from "./ModalHeader";

const Header = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const translations = language === "en" ? enTranslations : frTranslations;
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row align-items-center">
            {/* Logo and Menu Icon Section */}
            <div className="col-12 col-md-3 d-flex justify-content-between align-items-center mb-3 mb-md-0">
              <div className="logo">
                <RouterLink to="/">
                  <img src={Logo} alt="Logo" className="img-fluid" />
                </RouterLink>
              </div>
              {/* Show the menu icon only on small screens */}
              <button className="menu-icon d-md-none" onClick={toggleModal}>
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            </div>

            {/* Center Content Section */}
            <div className="col-12 col-md-6 text-center mb-3 mb-md-0">
              <div className="center-top">
                <div className="txt1">{translations.title1}</div>
                <div className="txt2">{translations.title2}</div>
                <div className="txt1">{translations.subtitle}</div>
              </div>
            </div>

            {/* Buttons Section (Donation & Menu) */}
            <div className="col-12 col-md-3 d-flex flex-column align-items-center align-items-md-end">
              <div className="mb-3 social-media-icons">
                <Link
                  to="https://www.instagram.com/impc_trust/"
                  target="_blank"
                >
                  <img src={insta} alt="Instagram" />
                </Link>
                <Link
                  to="https://www.facebook.com/profile.php?id=61564735025188"
                  target="_blank"
                >
                  <img src={facebook} alt="Facebook" />
                </Link>
                <Link to="https://www.youtube.com/@IMPC-holy" target="_blank">
                  <img src={youtube} alt="YouTube" />
                </Link>
                <Link to="https://x.com/home" target="_blank">
                  <img src={twitter} alt="twitter" />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/international-mandir-prabhandhak-council/"
                  target="_blank"
                  className="top-logo"
                >
                  <img src={linkedin} alt="twitter" />
                </Link>
              </div>
              <div className="mt-auto  d-flex flex-lg-row flex-md-row flex-column align-items-center gap-2">
                <RouterLink to="/donation" className="top-button no-underline">
                  <button>{translations.donationButtton}</button>
                </RouterLink>

                <button
                  className="btn top-second-button ms-2 ms-lg-2 ms-md-2"
                  onClick={toggleLanguage}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa-solid fa-language"></i>
                  <span className="ms-2">
                    {language === "en" ? "Hindi" : "English"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="menu">
        <div className="container">
          <Modal isOpen={isModalOpen} toggleModal={toggleModal}>
            <div className="model-menu">
              <ul>
                <li>
                  <NavLink
                    to="/"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.home}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about-us"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.aboutUs}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/explore-our-mission"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.exploreOurMisson}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/heritage-developement"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.templeDevelopmentAndPreservationCommittee}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/vikas-committee"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.developmentCommitteeForTempleWorkers}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/bharat-jal-sanrakshan-committee"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.bharatJalSanrakshanCommittee}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/shakti-bharat-mission"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.shaktiBharatMission}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/community-upliftment"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.communityUpliftmentAndServicesCommittee}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/join-as-a-volunteer"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.joinAsVolunteer}
                  </NavLink>
                </li>
                {/* <li>
                  <RouterLink to="/join-as-a-donar" onClick={toggleModal}>
                    {translations.joinAsDonor}
                  </RouterLink>
                </li> */}
                <li>
                  <NavLink
                    to="/join-as-a-committie-member"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.joinAsCommittieMember}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/adopt-now"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.adoptNow}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/donation"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.donation}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/our-team"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.ourTeam}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/privacy-policy"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    Privacy Policy
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/terms-and-conditions"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    Terms and Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact-us"
                    onClick={toggleModal}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {translations.contactUs}
                  </NavLink>
                </li>
                {/* <li className="language-switcher" onClick={toggleLanguage}>
                  <i className="fa-solid fa-language"></i>
                  {language === "en" ? "Hindi" : "English"}
                </li> */}
              </ul>
            </div>
          </Modal>

          <div id="cssmenu">
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  {translations.home}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  {translations.aboutUs}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/explore-our-mission"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  {translations.exploreOurMisson}
                </NavLink>
              </li>
              <li className="has-sub">
                <NavLink to="#">{translations.committies}</NavLink>
                <ul>
                  <li className={language === "en" ? "mb-2" : ""}>
                    <NavLink
                      to="/heritage-developement"
                      className={({ isActive }) =>
                         `${isActive ? "active-link" : ""} ${language === "en" ? "mb-2" : ""}`
                      }
                    >
                      {translations.templeDevelopmentAndPreservationCommittee}
                    </NavLink>
                  </li>
                  <li className={language === "en" ? "mb-2" : ""}>
                    <NavLink
                      to="/vikas-committee"
                      className={({ isActive }) =>
                         `${isActive ? "active-link" : ""} ${language === "en" ? "mb-2" : ""}`
                      }
                    >
                      {translations.developmentCommitteeForTempleWorkers}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/bharat-jal-sanrakshan-committee"
                      className={({ isActive }) =>
                        `${isActive ? "active-link" : ""} ${language === "en" ? "mb-2" : ""}`
                      }
                    >
                      {translations.bharatJalSanrakshanCommittee}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/shakti-bharat-mission"
                      className={({ isActive }) =>
                       `${isActive ? "active-link" : ""} ${language === "en" ? "mb-2" : ""}`
                      }
                    >
                      {translations.shaktiBharatMission}
                    </NavLink>
                  </li>
                  <li className={language === "en" ? "mb-2" : ""}>
                    <NavLink
                      to="/community-upliftment"
                      className={({ isActive }) =>
                        `${isActive ? "active-link" : ""} ${language === "en" ? "mb-2" : ""}`
                      }
                    >
                      {translations.communityUpliftmentAndServicesCommittee}
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="has-sub">
                <NavLink to="#">{translations.getInvolve}</NavLink>
                <ul>
                  <li>
                    <NavLink
                      to="/join-as-a-volunteer"
                      className={({ isActive }) =>
                        isActive ? "active-link" : ""
                      }
                    >
                      {translations.joinAsVolunteer}
                    </NavLink>
                  </li>
                  {/* <li>
                    <RouterLink to="/join-as-a-donar">
                      {translations.joinAsDonor}
                    </RouterLink>
                  </li> */}
                  <li>
                    <NavLink
                      to="/join-as-a-committie-member"
                      className={({ isActive }) =>
                        isActive ? "active-link" : ""
                      }
                    >
                      {translations.joinAsCommittieMember}
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/adopt-now"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  {translations.adoptNow}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/donation"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  {translations.donation}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/our-team"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  {translations.ourTeam}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact-us"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                >
                  {translations.contactUs}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
