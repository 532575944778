import React, { useEffect, useContext } from "react";
import { LanguageContext } from "../../useContext/LanguageContext";
import enTranslations from "../../assets/language/en.json";
import frTranslations from "../../assets/language/hn.json";
import Team1 from "../../assets/img/Team/TeamPage/Mahipal Bhai.png";
import Team2 from "../../assets/img/Team/TeamPage/Kapil Mishra.png";
import Team3 from "../../assets/img/Team/TeamPage/Rajesh Yadav.png";
import Team4 from "../../assets/img/Team/TeamPage/Prabal Pratap Singh Tamar.png";
import Team5 from "../../assets/img/Team/TeamPage/Deep Singh.png";
import Team6 from "../../assets/img/Team/TeamPage/Jagriti Motwani.png";
import Team7 from "../../assets/img/Team/TeamPage/Rohan Mahajan.png";
import Team8 from "../../assets/img/Team/TeamPage/Damini Vashish.png";
import Team9 from "../../assets/img/Team/TeamPage/Pawan Sindhi.png";
import Team10 from "../../assets/img/Team/TeamPage/Manu-Punia.png";

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useContext(LanguageContext);

  const translations = language === "en" ? enTranslations : frTranslations;

  return (
    <section className="container-fluid ourTeam-banner">
      <div className="container mt-5 our-team-page-main-section">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team1} alt="Team1" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_1}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_1}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_1}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team9} alt="placeholder" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_2}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_2}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_2}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team3} alt="Team3" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_3}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_3}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_3}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team2} alt="Team2" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_4}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_4}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_4}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team5} alt="Team4" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_5}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_5}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_5}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team4} alt="Team4" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_6}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_6}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_6}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team6} alt="Team6" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_7}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_7}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_7}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team7} alt="Team7" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_8}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_8}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_8}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team8} alt="Team8" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_9}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_9}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_9}</p>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex align-items-center">
                <img src={Team10} alt="Team10" className="team-image" />
                <div className="team-info ms-3">
                  <h4>{translations.OurTeamMemberName_10}</h4>
                  <p className="our-team-tittle">
                    {translations.OurTeamMemberPosition_10}
                  </p>
                </div>
              </div>
              <p>{translations.OurTeamMemberDescription_10}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
